import React, { useState } from "react";
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Snackbar,
  SnackbarCloseReason,
  Switch,
} from "@mui/material";
import { PermissionRecord, UserPermissionRecord } from "../../../../database/PublicTypes";
import { Profile } from "../../../../database/Profile";
import { NewUserPermission, deleteUserPermissionRecord, insertUserPermissionRecord } from "../data";

type PermissionSwitch = PermissionRecord & {
  granted: boolean;
}

type UserPermissionsProps = {
  profile: Profile;
  permissionRecords: PermissionRecord[];
  userPermissionRecords: UserPermissionRecord[];
  reloadData: () => Promise<void>;
}

const UserPermissions = ({ profile, permissionRecords, userPermissionRecords, reloadData }: UserPermissionsProps) => {
  const [displaySuccess, setDisplaySuccessAlert] = useState(false);
  const [displayError, setDisplayErrorAlert] = useState(false);

  const snackBarPosition: { vertical: "bottom" | "top", horizontal: "center"} = { vertical: "bottom", horizontal: "center" };

  const handleCloseSuccessAlert = (event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") return;
    setDisplaySuccessAlert(false);
  };
  const handleCloseErrorAlert = (event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") return;
    setDisplayErrorAlert(false);
  };

  const onPermissionToggled = async (permission: PermissionSwitch, state: boolean) => {
    const userPermission: NewUserPermission = {
      user_id: profile.id,
      permission_name: permission.name
    };

    if (state && !permission.granted) {
      const response = await insertUserPermissionRecord(userPermission);
      if (response?.error) {
        console.error(response.error);
        setDisplayErrorAlert(true);
        return;
      }
      setDisplaySuccessAlert(true);
      await reloadData();
    } else if (!state && permission.granted) {
      const targetUserPermission = userPermissionRecords.find(
        (userPermission) =>
          userPermission.user_id === profile.id &&
          userPermission.permission_name === permission.name,
      );
      if(!targetUserPermission) {
        setDisplayErrorAlert(true);
        return;
      }
      const response = await deleteUserPermissionRecord(targetUserPermission);
      if (response?.error) {
        console.error(response.error);
        setDisplayErrorAlert(true);
        return;
      }
      setDisplaySuccessAlert(true);
      await reloadData();
    }
  };

  const permissionSwitches: PermissionSwitch[] = permissionRecords.map(perm => {
    const isGranted = profile.permissions.includes(perm.name);
    return {...perm, granted: isGranted};
  })

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">Permissions</FormLabel>
      <FormGroup>
        {permissionSwitches.map((permission) => (
          <Box key={permission.id}>
            <FormControlLabel
              control={
                <Switch
                  checked={permission.granted}
                  onChange={(e, checked) =>
                    onPermissionToggled(permission, checked)
                  }
                  color="secondary"
                />
              }
              label={permission.name}
            />
            <FormHelperText sx={{ margin: "0 0 10px 50px" }}>
              {permission.description}
            </FormHelperText>
          </Box>
        ))}
      </FormGroup>
      <Snackbar
        open={displaySuccess}
        autoHideDuration={3500}
        onClose={handleCloseSuccessAlert}
        anchorOrigin={snackBarPosition}
      >
        <Alert
          onClose={handleCloseSuccessAlert}
          severity="success"
          sx={{ width: "100%", fontSize: "20px" }}
        >
          Updated product data successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={displayError}
        autoHideDuration={3500}
        onClose={handleCloseErrorAlert}
        anchorOrigin={snackBarPosition}
      >
        <Alert
          onClose={handleCloseErrorAlert}
          severity="error"
          sx={{ width: "100%", fontSize: "20px" }}
        >
          There was an error updating the product data.
        </Alert>
      </Snackbar>
    </FormControl>
  );
};

export default UserPermissions;
