import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { TextField } from "../../../../../../../components/TextField";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { supabase_products } from "../../../../../../../database/supabaseClient";
import { LedTapeOptionType, LedTapeOptionValueRecord } from "../../../../../../../database/Products";
import { PostgrestError } from "@supabase/supabase-js";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { DisplayAlert } from "../../../../types";

type OptionValueProps = {
  record?: LedTapeOptionValueRecord;
  type: LedTapeOptionType;
  ledTapeSlug: string;
  displayAlert: DisplayAlert;
  nextDisplayOrder?: number;
  onInserted?: () => void;
  onCancelNew?: () => void;
}

const OptionValue = ({record, type, ledTapeSlug, displayAlert, nextDisplayOrder, onInserted, onCancelNew }: OptionValueProps) => {

  const [existingRecord, setExistingRecord] = useState<LedTapeOptionValueRecord | undefined>(record);
  const [valueInput, setValueInput] = useState<string>(record?.value ?? '');
  const [displayNameInput, setDisplayNameInput] = useState<string>(record?.display_name ?? '');
  const [skuCodeInput, setSkuCodeInput] = useState<string>(record?.sku_code ?? '');
  const [easySpecCodeInput, setEasySpecCodeInput] = useState<string>(record?.easy_spec_code ?? '');

  const canSave = (
    valueInput !== existingRecord?.value ||
    displayNameInput !== existingRecord?.display_name ||
    skuCodeInput !== existingRecord?.sku_code ||
    easySpecCodeInput !== existingRecord?.easy_spec_code
  );

  const handleSave = () => {
    if(!canSave) return;
    if(record) updateRecord();
    else insertRecord();
  }

  const insertRecord = async () => {
    const { data, error } = await supabase_products
    .from('led_tape_option_values')
    .insert({
      led_tape_slug: ledTapeSlug,
      type: type,
      value: valueInput, 
      display_name: displayNameInput,
      sku_code: skuCodeInput,
      easy_spec_code: easySpecCodeInput,
      display_order: nextDisplayOrder
    })
    .select('*').single();
    processResponse(error);
    if(data && onInserted) onInserted();
  }

  const updateRecord = async () => {
    if(!canSave || !record) return;
    const { data, error } = await supabase_products
    .from('led_tape_option_values')
    .update({
      value: valueInput, 
      display_name: displayNameInput,
      sku_code: skuCodeInput,
      easy_spec_code: easySpecCodeInput
    })
    .eq('id', record.id)
    .select('*').single();
    processResponse(error);
    if(data) setExistingRecord(data);
  }

  const processResponse = (error: PostgrestError | null) => {
    if(error){
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    displayAlert('Saved successfully!', 'success');
  }

  return (
    <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end'>
      <TextField
        label='Value'
        value={valueInput}
        onChange={(e) => setValueInput(e.target.value)}
        updated={valueInput !== existingRecord?.value}
      />
      <TextField
        label='Display Name'
        value={displayNameInput}
        onChange={(e) => setDisplayNameInput(e.target.value)}
        updated={displayNameInput !== existingRecord?.display_name}
      />
      <TextField
        label='SKU Code'
        value={skuCodeInput}
        onChange={(e) => setSkuCodeInput(e.target.value)}
        updated={skuCodeInput !== existingRecord?.sku_code}
      />
      <TextField
        label='Easy Spec Code'
        value={easySpecCodeInput}
        onChange={(e) => setEasySpecCodeInput(e.target.value)}
        updated={easySpecCodeInput !== existingRecord?.easy_spec_code}
      />
      {
        canSave &&
        <Button 
          startIcon={<SaveOutlinedIcon />} 
          color="secondary" 
          variant="contained"
          onClick={() => handleSave()}
        >
          Save
        </Button>
      }
      {
        !existingRecord && onCancelNew &&
        <Button 
          startIcon={<CancelOutlinedIcon />} 
          color="error" 
          onClick={() => onCancelNew()}
        >
          Cancel
        </Button>
      }
    </Box>
  );
};

export default OptionValue;