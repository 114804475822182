import React, { useCallback, useState } from "react";
import { Box, CircularProgress, Button, Container, Typography } from "@mui/material";
import Header from "../../../components/Header";
import TextInput from "../../../components/TextInput";
import { queryQuote } from "../../../qbll/qbll";
import { QBLLResponse, Quote } from "../../../qbll/types";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import QBLocalLinkStatusBadge from "../../../qbll/QBLocalLinkStatusBadge";
import QbLocalLinkInstructions from "../../../qbll/QbLocalLinkInstructions";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import XmlPreview from "./XmlPreview";
import buildQuoteData from "./BuildQuoteData";

const HLStearnsXmlTool = () => {
  const [quoteNum, setQuoteNum] = useState<string>("");
  const [quoteVersion, setQuoteVersion] = useState<number>(1);
  const [zeroedPricing, setZeroedPricing] = useState<boolean>(true);
  const [blankDescription, setBlankDescription] = useState<boolean>(false);
  const [queryingQuote, setQueryingQuote] = useState(false);
  const [queryResponse, setQueryResponse] = useState<QBLLResponse<Quote> | null>(null);
  const { querying, status: qbllStatus } = useSelector((state: RootState) => state.qbll);

  const canQuery = !querying && qbllStatus !== null && qbllStatus.connection === "Good";

  const queryQuoteData = useCallback(
    async (refNum: string) => {
      setQueryResponse(null);
      const response = await queryQuote(refNum, true, false, false);
      setQueryResponse(response);
    },
    [setQueryResponse]
  );

  function onChange_QuoteNumber(newValue: string) {
    setQuoteNum(newValue);
  }

  function onChange_QuoteVersion(newValue: string) {
    let num = Math.abs(parseInt(newValue));
    num = Number.isNaN(num) ? 0 : num;
    setQuoteVersion(num);
  }

  function onChange_ZeroedPricing(newValue: boolean) {
    setZeroedPricing(newValue);
  }

  function onChange_BlankDescription(newValue: boolean) {
    setBlankDescription(newValue);
  }

  async function handleClickSubmitQuery(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    submitQuery()
  }
  
  async function submitQuery() {
    if (querying) return;
    setQueryingQuote(true);
    await queryQuoteData(quoteNum);
    setQueryingQuote(false);
  }

  const data = queryResponse !== null && queryResponse.data !== null ?
    { ...queryResponse.data }
    : null;

  const buildResult = data ?
    buildQuoteData(data, quoteVersion, zeroedPricing, blankDescription)
    : null;

  const quoteData: QuoteData | null = buildResult ? 
    buildResult.quoteData 
    : null;

  return (
    <Box m="20px">
      <Header
        title="HLS - QUOTE TO XML"
        subtitle="Convert a QuickBooks Quote to an XML file that HL Stearns can import into their system."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        <Box display='flex' flexDirection='row' justifyContent='space-between' mb='20px'>
          <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end'>
            <TextInput
              label="Quote #"
              value={quoteNum}
              updateValue={onChange_QuoteNumber}
              onEnterPress={submitQuery}
              disabled={!canQuery}
            />
            <Button
              variant="contained"
              color="info"
              onClick={(e) => handleClickSubmitQuery(e)}
              disabled={!canQuery}
              sx={{ marginLeft: "10px" }}
            >
              Query
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setQuoteNum("");
                setQueryResponse(null);
              }}
              disabled={!canQuery}
              sx={{ marginLeft: "5px" }}
            >
              Clear
            </Button>
          </Box>
          <QBLocalLinkStatusBadge position="right" />
        </Box>

        {qbllStatus?.connection === "Bad" && qbllStatus?.errorMsg === "0x80040408" && (
          <Box
            display="flex"
            marginBottom={3}
          >
            <ErrorOutlineOutlinedIcon />
            <Typography marginLeft="8px">Unable to connect to local QuickBook client.</Typography>
          </Box>
        )}

        {qbllStatus?.connection === "Bad" &&
          qbllStatus?.errorMsg === "Failed to fetch" && <QbLocalLinkInstructions />}

        {!querying && queryResponse?.status === 404 && (
          <Typography>Quote Not Found...</Typography>
        )}

        {quoteData && (
          <XmlPreview
            quoteData={quoteData}
            quoteVersion={quoteVersion}
            onChangeVersion={onChange_QuoteVersion}
            zeroedPricing={zeroedPricing}
            onChangeZeroedPricing={onChange_ZeroedPricing}
            blankDescription={blankDescription}
            onChangeBlankDescription={onChange_BlankDescription}
            warnings={buildResult?.warnings}
          />
        )}

        {queryingQuote && (
          <Box>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default HLStearnsXmlTool;
