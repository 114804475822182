import { Box, Button, MenuItem, TableCell, TableRow, useTheme } from "@mui/material";
import React, { useState } from "react";
import { LedTapeOptionValueRecord } from "../../../../../../../database/Products";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { SelectField } from "../../../../../../../components/TextField";
import { supabase_products } from "../../../../../../../database/supabaseClient";
import { DisplayAlert } from "../../../../types";

type NewConflictProps = {
  ledTapeSlug: string;
  optionValues: LedTapeOptionValueRecord[];
  onInserted: () => void;
  onCancel: () => void;
  displayAlert: DisplayAlert;
}

const NewConflict = ({ ledTapeSlug, optionValues, onInserted, onCancel, displayAlert }: NewConflictProps) => {
  const colors = useTheme().colors;

  const [option1Input, setOption1Input] = useState<string | undefined>(undefined);
  const [optionValue1Input, setOptionValue1Input] = useState<number | undefined>(undefined);
  const [option2Input, setOption2Input] = useState<string | undefined>(undefined);
  const [optionValue2Input, setOptionValue2Input] = useState<number | undefined>(undefined);

  const handleSave = async () => {
    if(!optionValue1Input || !optionValue2Input) return;
    const newRecord = { 
      led_tape_slug: ledTapeSlug, 
      option_value_id_1: optionValue1Input, 
      option_value_id_2: optionValue2Input
    };
    const { error } = await supabase_products
    .from('led_tape_option_values_conflicts')
    .insert(newRecord);
    if(error){
      console.error(error.message);
      displayAlert(`Error saving conflict: ${error.message}`, 'error');
      return;
    }
    onInserted();  
  }

  const option1Types = new Set<string>();
  optionValues.forEach(val => option1Types.add(val.type));

  const option1Values = option1Input ? optionValues.filter(val => val.type === option1Input).map(val => {
    return {id: val.id, display_name: val.display_name};
  }) : [];

  const option2Types = new Set<string>();
  optionValues.filter(val => val.type !== option1Input).forEach(val => option2Types.add(val.type));

  const option2Values = option2Input ? optionValues.filter(val => val.type === option2Input).map(val => {
    return {id: val.id, display_name: val.display_name};
  }) : [];

  return (
    <TableRow sx={{ bgcolor: colors.updatedValue.yellow }}>
      <TableCell>
        <Box display='flex' flexDirection='row' gap={2}>
          <Box width='100%'>
            <SelectField
              label='Option'
              value={option1Input ?? ''}
              onChange={(e) => setOption1Input(e.target.value)}
              fullWidth
            >
              {
                Array.from(option1Types).map(type => (
                  <MenuItem key={'opt1'+type} value={type}>{type}</MenuItem>
                ))
              }
            </SelectField>
          </Box>
          <Box width='100%'>
            {
              option1Input &&
              <SelectField
                label='Value'
                value={optionValue1Input ?? ''}
                onChange={(e) => setOptionValue1Input(Number(e.target.value))}
                fullWidth
              >
                {
                  option1Values.map(val => (
                    <MenuItem key={val.id} value={val.id}>{val.display_name}</MenuItem>
                  ))
                }
              </SelectField>
            }
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Box display='flex' flexDirection='row' gap={2}>
          <Box width='100%'>
            <SelectField
              label='Option'
              value={option2Input ?? ''}
              onChange={(e) => setOption2Input(e.target.value)}
              fullWidth
            >
              {
                Array.from(option2Types).map(type => (
                  <MenuItem key={'opt2'+type} value={type}>{type}</MenuItem>
                ))
              }
            </SelectField>
          </Box>
          <Box width='100%'>
            {
              option2Input &&
              <SelectField
                label='Value'
                value={optionValue2Input ?? ''}
                onChange={(e) => setOptionValue2Input(Number(e.target.value))}
                fullWidth
              >
                {
                  option2Values.map(val => (
                    <MenuItem key={val.id} value={val.id}>{val.display_name}</MenuItem>
                  ))
                }
              </SelectField>
            }
          </Box>
        </Box>
      </TableCell>
      <TableCell align="right">
        <Box display='flex' justifyContent='flex-end' gap={2}>
          <Button
            onClick={() => handleSave()}
            startIcon={<SaveOutlinedIcon />}
            variant="contained"
            color="secondary"
          >
            Save
          </Button>
          <Button
            onClick={() => onCancel()}
            color="primary"
          >
            Cancel
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default NewConflict;