export function createCSVBlob(csv: string): Blob {
  return new Blob([csv], { type: 'text/csv' });
}

export function downloadCSV(blob: Blob, filename: string): void {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url); // cleanup the URL object
}

export function formatForCSV(value: string): string {
  if (value == null) {
      return '';
  }

  // Escape double quotes by replacing " with ""
  const escapedValue = value.replace(/"/g, '""');

  // Enclose in double quotes if the value contains a comma, newline, or double quote
  if (/[,"\n]/.test(escapedValue)) {
      return `"${escapedValue}"`;
  }

  return escapedValue;
}
