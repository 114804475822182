import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

export function usePageNavigateWarning(message: string, when: boolean) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof navigator.push>) => {
      if (window.confirm(message)) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [message, navigator, when]);
}
