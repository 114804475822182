import React, { useMemo, useState } from "react";
import { Box, Button, CircularProgress, Container, FormControl, InputLabel, LinearProgress, MenuItem, Select, Typography } from "@mui/material";
import Header from "../../../components/Header";
import QBLocalLinkStatusBadge from "../../../qbll/QBLocalLinkStatusBadge";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SalesOrderQueryFilters from "./components/SalesOrderQueryFilters";
import { abortQuery, QbObjectType, QbObjectTypes, queryQuotesByDateRange, querySalesOrdersByDateRange } from "../../../qbll/qbll";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { DataGrid, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import { QueryParams, QuoteRow, SalesOrderRow } from "./types";
import { QuoteColumns, SalesOrderColumns } from "./columns";
import { Quote, SalesOrder } from "../../../qbll/types";
import { compileLineItems, formatBatchToRows } from "./data";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { createCSVBlob, downloadCSV, formatForCSV } from "../../../utils/Csv";

type Progress = { 
  received: number, 
  remaining: number, 
  batchTimeStamps: number[],
  startMs: number;
}

const QbDataQuery = () => {
  const [selectedQbObjectType, setSelectedQbObjectType] = useState<QbObjectType>('Sales Order');
  const [columnFormat, setColumnFormat] = useState<QbObjectType>('Sales Order');
  const [queryParams, setQueryParams] = useState<QueryParams | null>(null);
  const [isQuerying, setIsQuerying] = useState<boolean>(false);
  const [progress, setProgress] = useState<Progress>({ received: 0, remaining: 0, batchTimeStamps: [], startMs: 0 });
  const [rows, setRows] = useState<SalesOrderRow[] | QuoteRow[]>([]);
  const {status: qbllStatus, queryId} = useSelector((state: RootState) => state.qbll);
  const canQuery = (
    (queryParams && queryParams.createdDateFrom && queryParams.createdDateTo) &&
    !isQuerying && qbllStatus !== null && qbllStatus.connection === "Good"
  );

  const columns = useMemo(() => {
    if(!queryParams) return [];
    const { includeLineItems, compileLineItems } = queryParams;
    const targetColumns = columnFormat === 'Sales Order' ? SalesOrderColumns : QuoteColumns;
    if(!includeLineItems){
      return targetColumns.filter(col => !(col.lineItem));
    }else{
      return compileLineItems ? targetColumns.filter(col => col.lineItem && col.field !== 'lineItem_txnLineId') : targetColumns;
    }
  }, [rows, selectedQbObjectType, columnFormat, queryParams]);

  const handleChangeObjecType = (newValue: string) => {
    if (QbObjectTypes.includes(newValue as QbObjectType)) {
      setSelectedQbObjectType(newValue as QbObjectType);
      if (rows.length === 0) setColumnFormat(newValue as QbObjectType);
    } else {
      console.warn("Invalid QbObjectType selected:", newValue);
    }
  };

  const handleChangeQueryParams = (newParams: QueryParams) => {
    setQueryParams(newParams);
  };

  const handleQueryClick = () => {
    if(!canQuery) return;
    const { createdDateFrom, createdDateTo, includeLineItems } = queryParams;
    if(!(createdDateFrom && createdDateTo)) return;
    if(selectedQbObjectType !== columnFormat) setColumnFormat(selectedQbObjectType);
    setProgress({ received: 0, remaining: 0, batchTimeStamps: [], startMs: Date.now()})
    setRows([]);
    setIsQuerying(true);
    // TODO: Check selected object type and do appropriate query
    if(selectedQbObjectType === 'Sales Order'){
      querySalesOrdersByDateRange(
        createdDateFrom, 
        createdDateTo, 
        includeLineItems, 
        handleReceivedBatch, 
        handleOnErrorQuerying, 
        handleOnCompleteQuerying
      );
    }else{
      queryQuotesByDateRange(
        createdDateFrom, 
        createdDateTo, 
        includeLineItems, 
        handleReceivedBatch, 
        handleOnErrorQuerying, 
        handleOnCompleteQuerying
      );
    }
  };

  const handleReceivedBatch = (batch: (SalesOrder | Quote)[], remainingCount: number) => {
    console.log(batch);
    setProgress(prev => { 
      const received = batch.length + prev.received;
      const newTimestamps = [...prev.batchTimeStamps, Date.now()];
      if(newTimestamps.length > 3) newTimestamps.shift();
      return{
        ...prev,
        received: received,
        remaining: remainingCount, 
        batchTimeStamps: newTimestamps,
      }
    });
    if(!queryParams) return;    
    setRows(prev => {
      const formattedRows: (SalesOrderRow | QuoteRow)[] = formatBatchToRows(prev.length, selectedQbObjectType, batch, queryParams);
      if(queryParams.includeLineItems && queryParams.compileLineItems){
        return compileLineItems(selectedQbObjectType, prev, formattedRows);
      }
      return [...prev, ...formattedRows];
    });
  }

  const handleOnErrorQuerying = (err: string) => {
    console.error(err);
    setIsQuerying(false);
  }

  const handleOnCompleteQuerying = () => {
    console.log('Sync completed.')
    setIsQuerying(false);
  }

  const handleExportCSV = () => {
    if(rows.length === 0) return;
    const columnHeaderNames = columns.map(col => col.headerName);
    const columnFieldNames = columns.map(col => col.field);
    const csvRows = [
      columnHeaderNames.join(','),
      ...rows.map(row => columnFieldNames.map(key => {
        const specValue = row[key as keyof (SalesOrderRow | QuoteRow)];
        return formatForCSV(specValue?.toString() ?? '');
      }).join(','))
    ];
    const csvString = csvRows.join('\n');
    const csvBlob = createCSVBlob(csvString);
    downloadCSV(csvBlob, `qbData_${Date.now()}.csv`);
  }

  const totalRecords = (progress.received + progress.remaining);
  const progressPercentage_NaN = (progress.received / totalRecords) * 100;
  const progressPercentage = isNaN(progressPercentage_NaN) ? 0 : progressPercentage_NaN;

  const progressCount = totalRecords === 0 ? 'Awaiting initial response from QuickBooks' : `(Querying ${selectedQbObjectType}s: ${progress.received.toLocaleString()}/${totalRecords.toLocaleString()})`;

  const batchesRemaining = Math.ceil(progress.remaining / 20);
  const { batchTimeStamps } = progress;
  let avgBatchTime = 0;
  let estimatedTimeMs = 0;
  let minutesRemaining = 0;
  let secondsRemaining = 0;
  if(batchTimeStamps.length > 2 && batchesRemaining !== 0){
    const intervals = batchTimeStamps.slice(1)
    .map((time, index) => time - batchTimeStamps[index]);
    avgBatchTime = intervals.reduce((prev, curr) => prev + curr, 0) / intervals.length;
    estimatedTimeMs = batchesRemaining * avgBatchTime;
    minutesRemaining = Math.floor(estimatedTimeMs / 60000); // Convert milliseconds to minutes
    secondsRemaining = Math.floor((estimatedTimeMs % 60000) / 1000); // Get remaining seconds
  }
  const timeRemaining = (minutesRemaining + secondsRemaining > 0) ? `${minutesRemaining}m ${secondsRemaining}s` : '';

  const columnVisibility: GridColumnVisibilityModel = {};
  columnVisibility.id = false;

  const timeElapsedMs = (batchTimeStamps[batchTimeStamps.length - 1] ?? 0) - progress.startMs;
  const timeElapsed_mins = Math.floor(timeElapsedMs / 60000); // Convert milliseconds to minutes
  const timeElapsed_secs = Math.floor((timeElapsedMs % 60000) / 1000); // Get remaining seconds

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box m="20px">
        <Header
          title="QB DATA QUERY"
          subtitle="A tool for querying QuickBooks for raw data across various objects."
        />
        <Container style={{ padding: "20px", maxWidth: "1400px" }}>
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <FormControl>
              <InputLabel id='object-select-label'>Object</InputLabel>
              <Select
                labelId='object-select-label'
                value={selectedQbObjectType}
                label='Object'
                onChange={(e) => handleChangeObjecType(e.target.value)}
                disabled={isQuerying}
              >
                {
                  QbObjectTypes.map(type => 
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  )
                }
              </Select>
            </FormControl>
            <QBLocalLinkStatusBadge position="right" />
          </Box>
          <SalesOrderQueryFilters
            isQuerying={isQuerying}
            OnChangeQueryParams={handleChangeQueryParams}
          />
          <Box display='flex' flexDirection='row' justifyContent='space-between'>
            <Box display='flex' flexDirection='row' gap={2}>
              <Box>
                <Button
                  onClick={handleQueryClick}
                  disabled={!canQuery}
                >
                  Query
                </Button>
              </Box>
              {
                isQuerying &&
                <CircularProgress />
              }
              {
                isQuerying && queryId &&
                <Box>
                  <Button color="error" onClick={() => abortQuery(queryId)} startIcon={<CancelOutlinedIcon />}>Abort</Button>
                </Box>
              }
            </Box>
            {
              rows.length > 0 &&
              <Box>
                <Typography>Query {isQuerying ? 'in-progress' : 'completed'}.. {columnFormat}s: ({progress.received}).. Rows: ({rows.length}).. Time Elapsed: {timeElapsed_mins}m {timeElapsed_secs}s</Typography>
              </Box>
            }
            <Box>
              <Box>
                <Button onClick={handleExportCSV} disabled={isQuerying}>
                  Export CSV
                </Button>
              </Box>
            </Box>
          </Box>
          {
            isQuerying &&
            <Box my={2}>
              <Typography>{Math.round(progressPercentage)}% {progressCount}... {timeRemaining}</Typography>
              <LinearProgress variant="determinate" color="secondary" value={progressPercentage} />
            </Box>
          }
          {
            columnFormat === 'Sales Order' &&
            <DataGrid
              rows={rows as SalesOrderRow[]}
              columns={columns as GridColDef<SalesOrderRow>[]}
              density="compact"
              disableRowSelectionOnClick
              pagination // Enable pagination
              initialState={{
                columns: {
                  columnVisibilityModel: columnVisibility,
                },
              }}
              sx={{ marginY: 2 }}
            />
          }
          {
            columnFormat === 'Quote' &&
            <DataGrid
              rows={rows as QuoteRow[]}
              columns={columns as GridColDef<QuoteRow>[]}
              density="compact"
              disableRowSelectionOnClick
              pagination // Enable pagination
              initialState={{
                columns: {
                  columnVisibilityModel: columnVisibility,
                },
              }}
              sx={{ marginY: 2 }}
            />
          }
        </Container>
      </Box>
    </LocalizationProvider>
  );
};

export default QbDataQuery;