import { LedTapeOptionType } from "../../../database/Products";
import { Database, Enums } from "../../../database/database.types";

export const NearestInt = (value: string) => {
  const num = Number(value);
  if(isNaN(num)) return 0;
  return Math.round(num);
}

export const FloatValue = (value: string): number => {
  const num = Number(value);
  if(isNaN(num)) return 0;
  return num;
}

export const WhiteLightOptions: Set<LedTapeOptionType> = new Set(['SDCM', 'Lumens', 'Kelvin Temp']);
export const SingleColorOptions: Set<LedTapeOptionType> = new Set(['Color', 'Lumens']);

export const GetOptions = (ledType: Enums<'LED Type'>) =>{
  switch(ledType) {
    case 'Color Tuning': return WhiteLightOptions;
    case 'Dim To Warm': return WhiteLightOptions;
    case 'Pixel': return WhiteLightOptions;
    case 'RGB': return WhiteLightOptions;
    case 'RGBCT': return WhiteLightOptions;
    case 'RGBW': return WhiteLightOptions;
    case 'RGBWA': return WhiteLightOptions;
    case 'Single Color': return SingleColorOptions;
    case 'Static White': return WhiteLightOptions;
    case 'UV': return WhiteLightOptions;
    default: return new Set<LedTapeOptionType>([])
  }
}

export const parseNumber = (value: string): number => {
  const parsed = Number(value);
  if (isNaN(parsed)) {
    throw new Error(`Value "${value}" is not a valid number.`);
  }
  return parsed;
};

export const parseNullableNumber = (value: string): number | null => {
  if (value === '' || value === null || value === undefined) {
    return null;
  }
  const parsed = Number(value);
  if (isNaN(parsed)) {
    throw new Error(`Value "${value}" is not a valid number.`);
  }
  return parsed;
};

export const parseUomEnum = (value: string): Database["public"]["Enums"]["UoM"] => {
  switch(value){
    case 'FT':
      return 'FT';
    case 'IN':
      return 'IN';
    case 'MM':
      return 'MM';
    default:
      return 'FT';
  }
};

export const parseNullableSDCM = (value: string): Enums<'SDCM'> | null=> {
  switch(value){
    case '1-Step': return '1-Step';
    case '2-Step': return '2-Step';
    case '3-Step': return '3-Step';
    default: return null;
  }
}