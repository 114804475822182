import { Address } from "../qbll/types";

export const GetAddressString = (address: Address) => {
  let addressStr = '';
  if(address.line1) addressStr += `${address.line1}, `;
  if(address.line2) addressStr += `${address.line2}, `;
  if(address.line3) addressStr += `${address.line3}, `;
  if(address.line4) addressStr += `${address.line4}, `;
  if(address.line5) addressStr += `${address.line5}, `;
  if(address.city) addressStr += `${address.city}, `;
  if(address.state) addressStr += `${address.state}`;
  if(address.postalCode) addressStr += ` ${address.postalCode}`;
  if(address.country) addressStr += `, ${address.country}`;
  return addressStr;
}