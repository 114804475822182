import { Box, Button, Card, CardContent, Divider, List, ListItem, Tooltip, Typography, Fade, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from '../../../../../components/TextField';
import { ChannelAssemblyRecord, IsChannelAssemblyOptionType, extractVariableNames } from '../../../../../database/Products';
import { supabase_products } from '../../../../../database/supabaseClient';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { DisplayAlert } from '../../types';

const formulaValidation = (value: string) => {
  if(value === '') return 'Pleaes provide a formula.';
  const match = value.match(/\{([^}]+)\}/);
  if(!match) return; // No inner params detected.
  const param = match[1];
  if(IsChannelAssemblyOptionType(param)) return;
  return `Cannot find option or attribute slug with name: ${param}`;
};

type SkuFormulaProps = {
  record: ChannelAssemblyRecord;
  reloadRecord: () => Promise<void>;
  displayAlert: DisplayAlert;
}

const SkuFormula = ({ record, reloadRecord, displayAlert }: SkuFormulaProps) => {
  const colors = useTheme().colors;

  const [skuFormulaInput, setSkuFormulaInput] = useState<string>(record.sku_formula);
  const [hasLenses, setHasLenses] = useState<boolean>(false);
  const [hasMountings, setHasMountings] = useState<boolean>(false);
  const [hasFinishes, setHasFinishes] = useState<boolean>(false);
  const [copiedSlug, setCopiedSlug] = useState<boolean>(false);

  const queryOptionsData = useCallback(async () => {
    const [lensRes, mountRes, finishRes] = await Promise.all([
      supabase_products.from('channel_lenses').select('id').limit(1),
      supabase_products.from('channel_mountings').select('id').limit(1),
      supabase_products.from('channel_finishes').select('id').limit(1)
    ]);
    if((lensRes.data?.length ?? 0) > 0) setHasLenses(true);
    if((mountRes.data?.length ?? 0) > 0) setHasMountings(true);
    if((finishRes.data?.length ?? 0) > 0) setHasFinishes(true);
  }, []);

  useEffect(() => {
    queryOptionsData();
  }, []);

  const updateAssembly = async () => {
    const updatedRecord = {
      sku_formula: skuFormulaInput
    }
    const { error } = await supabase_products
    .from('channel_assemblies').update({...updatedRecord}).eq('id', record.id);
    if(error) {
      console.error(error);
      displayAlert(`Failed to save SKU Formula. Error Message: "${error.message}"`, 'error');
      return false;
    }
    reloadRecord().then(() => {
      displayAlert('Saved SKU Formula successfully!', 'success');
    });
    return true;
  };

  const handleSave = () => {
    if(!canSave) return;
    updateAssembly().then();
  }

  const handleClickToCopy = (content: string) => {
    navigator.clipboard.writeText(content);
    setCopiedSlug(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setCopiedSlug(false);
  };

  const handleFormulaInputChange = (newValue: string) => {
    if (/^[a-z0-9{} -]+$/i.test(newValue) || newValue === "") {
      setSkuFormulaInput(newValue);
    }
  }

  const updated = (skuFormulaInput !== record.sku_formula);  
  const canSave = (skuFormulaInput !== '' && updated);

  let hasDuplicateSlugs = false;
  const hasSlugs: Set<string> = new Set();
  const allInputSlugs = extractVariableNames(skuFormulaInput);
  allInputSlugs.forEach(slug => {
    if(hasSlugs.has(slug)){
      hasDuplicateSlugs = true;
    }else{
      hasSlugs.add(slug)
    }
  });

  const errors: Set<string> = new Set();
  if(hasDuplicateSlugs) errors.add('Formulas contain duplicate option slugs.');
  errors.add(formulaValidation(skuFormulaInput) ?? '');
  errors.delete('');
  const formulaErrorsArr = [ ...errors ];

  const hover = {
    "&:hover": {
      cursor: "pointer",
      color: colors.blueAccent[500],
    }
  };

  return (
    <Card>
      <CardContent>
        <Box display='flex' flexDirection='column'>
          <Box>
            <TextField 
              label='SKU Formula'
              value={skuFormulaInput} 
              onChange={(e) => handleFormulaInputChange(e.target.value)}
              updated={updated}
              minWidth='350px'
            />
          </Box>
          {
            errors.size > 0 &&
            <List dense={true} >
              {
                formulaErrorsArr.map((err, i) => (
                  <ListItem key={i} sx={{ color: 'red'}}>• {err}</ListItem>
                ))
              }
            </List>
          }
          <Box mt={2} maxWidth='250px'>
            <Typography>Channel Assembly Option slugs:</Typography>
            <List dense={true}>
              {
                hasLenses ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleClickToCopy(`{lens}`)} sx={hover}>{`{lens}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{lens}`}</ListItem>
              }
              {
                hasMountings ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleClickToCopy(`{mounting}`)} sx={hover}>{`{mounting}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{mounting}`}</ListItem>
              }
              {
                hasFinishes ?
                <Tooltip
                  title={copiedSlug ? "Copied!" : "Click to copy"}
                  placement="left"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: { enter: 800, exit: 0 } }}
                  onClose={handleOnCloseCopyTooltip}
                >
                  <ListItem onClick={() => handleClickToCopy(`{finish}`)} sx={hover}>{`{finish}`}</ListItem>
                </Tooltip>
                :
                <ListItem style={{color: 'gray'}}>{`{finish}`}</ListItem>
              }
            </List>
          </Box>
          <Divider />
          <Box mt={2}>
            <Button 
              color='secondary' 
              variant='contained' 
              startIcon={<SaveOutlinedIcon />}
              disabled={!updated || !canSave}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SkuFormula;