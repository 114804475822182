import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { supabase_products } from '../../../../../database/supabaseClient';
import { ChannelAssemblyRecord } from '../../../../../database/Products';
import { ClickableTextCell, HeadCell, StyledTableRowHover, TextCell } from '../../components/table';
import { EnsureSingle } from '../../../../../database/Utils';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { TextField } from '../../../../../components/TextField';
import AddProduct from '../../components/AddProduct';

type ChannelAssembliesListProps = {
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const ChannelAssembliesList = ({ displayAlert }: ChannelAssembliesListProps) => {
  const [addProduct, setAddProduct] = useState<boolean>(false);
  const [data, setData] = useState<(Partial<ChannelAssemblyRecord> & { channelName: string })[] | null>(null);
  const [filter, setFilter] = useState<string>('');

  const queryData = async () => {
    const { data, error } = await supabase_products.from('channel_assemblies')
    .select('id, name, sku_formula, spec_sheet_url, channel( name )')
    .order('name', { ascending: true });
    if(error) console.error(error);
    const transformeData = data?.map(d => {
      const channelName = EnsureSingle(d.channel)?.name ?? '';
      return {
        ...d,
        channelName: channelName
      }
    });
    setData(transformeData ?? []);
  };

  useEffect(() => {
    queryData();
  }, []);

  const filteredData = data?.filter(d => d.name?.toLowerCase()?.includes(filter.toLowerCase()));

  return (
    <>
    <Box display='flex' flexDirection='row' justifyContent='space-between'>
      <Box>{/* Filters */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', marginBottom: '5px' }}>
          <SearchOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
          <TextField 
            id="filter-input" 
            label="Search Name"
            onChange={(e) => setFilter(e.target.value)}
          />
        </Box>
      </Box>
      <Box>{/* Add Product */}
        <Button
          startIcon={<AddOutlinedIcon />} 
          color='success' 
          variant='contained'
          onClick={() => setAddProduct(true)}
        >
          Add Product
        </Button>
      </Box>
    </Box>
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <HeadCell>Name</HeadCell>
            <HeadCell>Channel</HeadCell>
            <HeadCell>SKU Formula</HeadCell>
            <HeadCell>Spec Sheet</HeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredData?.map((d, i) => (
              <StyledTableRowHover key={i}>
                <ClickableTextCell clickTo={`channel-assembly/${d.id}`}>{d.name}</ClickableTextCell>
                <TextCell>{d.channelName}</TextCell>
                <TextCell>{d.sku_formula}</TextCell>
                <ClickableTextCell clickTo={d.spec_sheet_url ?? ''}>{d.spec_sheet_url}</ClickableTextCell>
              </StyledTableRowHover>
            ))
          }
        {
          !data &&
          <StyledTableRowHover>
            <TableCell sx={{ border: 'none' }}>
              <CircularProgress />
            </TableCell>
          </StyledTableRowHover>
        }
        </TableBody>
      </Table>
    </TableContainer>
    {
      addProduct &&
      <AddProduct 
        productType='Channel Assembly' 
        onClose={() => setAddProduct(false)}
        displayAlert={displayAlert}
      />
    }
    </>
  );
};

export default ChannelAssembliesList;