import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { PermissionRecord, UserPermissionRecord } from "../../../../database/PublicTypes";
import { Profile } from "../../../../database/Profile";
import { deleteUserPermissionRecord, getPermissions, insertUserPermissionRecord, NewUserPermission } from "../data";
import Cell from "./components/Cell";
import PermissionRow from "./components/PermissionRow";

type PermissionListProps = {
  userPermissions: UserPermissionRecord[];
  profiles: Profile[];
  OnDisplayAlert: (success: boolean) => void;
  OnReloadData: () => Promise<void>;
}

const PermissionsList = ({userPermissions, profiles, OnDisplayAlert, OnReloadData }: PermissionListProps) => {
  const colors = useTheme().colors;
  const [permissions, setPermissions] = useState<PermissionRecord[]>([]);
  const [profilesMod, setProfilesMod] = useState<{permission: PermissionRecord, profiles: (Profile & { granted: boolean})[], granted: boolean} | null>(null);
  const [profilesFilter, setProfilesFilter] = useState<string>('');

  const queryData = useCallback(async () => {
    const data = await getPermissions();
    setPermissions(data);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const handleOnViewProfiles = (permission: PermissionRecord, profiles: Profile[], granted: boolean) => {
    const newProfiles = profiles.map(profile => ({...profile, granted: granted}));
    setProfilesMod({permission: permission, profiles: newProfiles, granted: granted});
  };

  const handlePermissionToggle = async (profile: (Profile & { granted: boolean}), permission: PermissionRecord, newState: boolean) => {
    let updateSuccessful = false;
    if (newState) {
      const userPermission: NewUserPermission = {
        user_id: profile.id,
        permission_name: permission.name
      };
      const response = await insertUserPermissionRecord(userPermission);
      if (response?.error) {
        console.error(response.error);
        OnDisplayAlert(false);
        return;
      }
      OnDisplayAlert(true);
      updateSuccessful = true;
    } else if (!newState) {
      const targetUserPermission = userPermissions.find(
        (userPermission) =>
          userPermission.user_id === profile.id &&
          userPermission.permission_name === permission.name,
      );
      if(!targetUserPermission) {
        OnDisplayAlert(false);
        return;
      }
      const response = await deleteUserPermissionRecord(targetUserPermission);
      if (response?.error) {
        console.error(response.error);
        OnDisplayAlert(false);
        return;
      }
      OnDisplayAlert(true);
      updateSuccessful = true;
    }
    if(updateSuccessful && profilesMod){
      profile.granted = newState;
      OnReloadData();
    }
  }

  const filteredProfiles = profilesMod?.profiles.filter(profile => profile.full_name?.toLowerCase().includes(profilesFilter.toLowerCase())) ?? [];

  return (
    <Box>
      <TableContainer sx={{ marginBottom: "50px" }}>
        <Table>
          <TableHead sx={{ background: colors.primary[400] }}>
            <TableRow>
              <Cell>
                <strong>Name</strong>
              </Cell>
              <Cell>
                <strong>Description</strong>
              </Cell>
              <Cell>
                <strong>Users - Granted</strong>
              </Cell>
              <Cell>
                <strong>Users - Disallowed</strong>
              </Cell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              permissions.map((permission, index) => (
                <PermissionRow
                  key={index}
                  permission={permission}
                  userPermissions={userPermissions}
                  profiles={profiles}
                  OnViewProfiles={(profiles: Profile[], granted: boolean) => handleOnViewProfiles(permission, profiles, granted)}
                />
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={!!profilesMod} onClose={() => setProfilesMod(null)}>
        {
          profilesMod &&
          <>
            <DialogTitle>{profilesMod.permission.name}</DialogTitle>
            <DialogContent>
              <DialogContentText>These are all the profiles which are <b>{profilesMod.granted ? 'Granted' : 'Disallowed'}</b> the permission.</DialogContentText>
              <TextField 
                value={profilesFilter}
                placeholder="Filter by name..."
                onChange={(e) => setProfilesFilter(e.target.value)}
                sx={{ marginY: 2}}
              />
              {
                filteredProfiles.map(profile => (
                  <Box key={profile.id}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={profile.granted}
                          onChange={(e, checked) => handlePermissionToggle(profile, profilesMod.permission, checked)}
                          color="secondary"
                        />
                      }
                      label={profile.full_name}
                    />
                  </Box>
                ))
              }
            </DialogContent>
          </>
        }
      </Dialog>
    </Box>
  );
};

export default PermissionsList;
