import { Dayjs } from "dayjs";
import { setQuerying, setStatus } from "../store/reducers/qbllSlice";
import { store } from "../store/store";
import { ItemSiteInventoryData, QBLLResponse, QBLLStatus,Quote,/*, TxnInventoryData*/ 
SalesOrder} from "./types";

const API_URL = 'http://localhost:5207/api/';

const Error_AlreadyQuerying = {
  status: 300,
  data: null,
  error: "Busy performing another query."
}

export const QbObjectTypes = ['Sales Order', 'Quote'] as const;
export type QbObjectType = typeof QbObjectTypes[number];

const isAlreadyQuerying = () => store.getState().qbll.querying;

const setQueryState = (querying: boolean, queryStr: string, queryId?: string) => {
  store.dispatch(setQuerying({ querying: querying, query: queryStr, queryId: queryId }));
}

const query = async <T,>(queryStr: string): Promise<QBLLResponse<T>> => {
  const fullQueryStr = `${API_URL}${queryStr}`;
  store.dispatch(setQuerying({ querying: true, query: fullQueryStr }));
  setQueryState(true, fullQueryStr);
  try {
    const response = await fetch(fullQueryStr);
    const data = await response.json() as T;
    console.log(data);
    setQueryState(false, "");
    return { status: response.status, data: data, error: "" };
  } catch (error) {
    console.error("QBLL encountered an error while querying.");
    console.error(error);
    setQueryState(false, "");
    return { status: 500, data: null, error: JSON.stringify(error) };
  }
}

const queryInBatches = async <T,>(
  queryStr: string,
  onBatchReceived: (batch: T[], remainingCount: number) => void,
  onError: (error: string) => void,
  onComplete: () => void
) => {
  const fullQueryStr = `${API_URL}${queryStr}`;
  store.dispatch(setQuerying({ querying: true, query: fullQueryStr }));
  setQueryState(true, fullQueryStr);

  try {
    const eventSource = new EventSource(fullQueryStr);
    eventSource.onmessage = (event) => {
      try {
        const response = JSON.parse(event.data) as { data: T[]; remainingCount: number, queryId: string };
        console.log(response.data)
        const normalizedData = transformKeysToCamelCase(response.data);
        
        onBatchReceived(normalizedData, response.remainingCount);
        setQueryState(true, fullQueryStr, response.queryId);
      } catch (err) {
        console.error("Error parsing batch:", err);
        onError("Failed to parse batch data");
        eventSource.close();
      }
    };

    eventSource.onerror = (err) => {
      console.error("SSE Error:", err);
      onError("Streaming error occurred");
      eventSource.close();
      setQueryState(false, "");
    };

    eventSource.onopen = () => console.log("SSE Connection Opened");

    eventSource.addEventListener("close", () => {
      console.log("SSE Connection Closed");
      setQueryState(false, "");
      onComplete();
      eventSource.close(); // Cleanup after closure
    });
  } catch (error) {
    console.error("Error initializing SSE:", error);
    setQueryState(false, "");
    onError(JSON.stringify(error));
  }
};

export const querySalesOrdersByDateRange = async (
  createdDateFrom: Dayjs, 
  createdDateTo: Dayjs,
  includeLineItems: boolean,
  onBatchReceived: (batch: SalesOrder[], remainingCount: number) => void,
  onError: (error: string) => void,
  onComplete: () => void) => {
  const createdDateFromStr = createdDateFrom.format("YYYY-MM-DD");
  const createdDateToStr = createdDateTo.format("YYYY-MM-DD");
  if(isAlreadyQuerying()){
    onError(Error_AlreadyQuerying.error);
    return;
  }
  const queryStr = `salesorder/date/?createdDateFrom=${createdDateFromStr}&createdDateTo=${createdDateToStr}&includeLineItems=${includeLineItems}`;
  await queryInBatches<SalesOrder>(queryStr, onBatchReceived, onError, onComplete);
}

export const queryQuotesByDateRange = async (
  createdDateFrom: Dayjs, 
  createdDateTo: Dayjs,
  includeLineItems: boolean,
  onBatchReceived: (batch: Quote[], remainingCount: number) => void,
  onError: (error: string) => void,
  onComplete: () => void) => {
  const createdDateFromStr = createdDateFrom.format("YYYY-MM-DD");
  const createdDateToStr = createdDateTo.format("YYYY-MM-DD");
  if(isAlreadyQuerying()){
    onError(Error_AlreadyQuerying.error);
    return;
  }
  const queryStr = `quote/date/?createdDateFrom=${createdDateFromStr}&createdDateTo=${createdDateToStr}&includeLineItems=${includeLineItems}`;
  await queryInBatches<Quote>(queryStr, onBatchReceived, onError, onComplete);
}

export const querySalesOrder = async (refNumber: string, includeLineItems: boolean, checkInventory: boolean, includeSites: boolean): Promise<QBLLResponse<SalesOrder>> => {
  if(isAlreadyQuerying()) return Error_AlreadyQuerying;
  const queryStr = `salesorder/${refNumber}?includeLineItems=${includeLineItems}&checkInventory=${checkInventory}&inventorySites=${includeSites}`;
  const response = await query<SalesOrder>(queryStr);
  return response;
}

export const queryQuote = async (refNumber: string, includeLineItems: boolean, checkInventory: boolean, includeSites: boolean): Promise<QBLLResponse<Quote>> => {
  if(isAlreadyQuerying()) return Error_AlreadyQuerying;
  const queryStr = `quote/${refNumber}?includeLineItems=${includeLineItems}&checkInventory=${checkInventory}&inventorySites=${includeSites}`;
  const response = await query<Quote>(queryStr);
  return response;
}

export const queryItemSiteInventory = async(itemListId: string): Promise<QBLLResponse<ItemSiteInventoryData[]>> => {
  if(isAlreadyQuerying()) return Error_AlreadyQuerying;
  const queryStr = `inventory/bysite?itemListId=${itemListId}`;
  const response = await query<ItemSiteInventoryData[]>(queryStr);
  return response;
}

export const refreshStatus = async () => {
  if(isAlreadyQuerying()) return Error_AlreadyQuerying;
  const queryStr = "status";
  const response = await query<QBLLStatus>(queryStr);
  const badStatus: QBLLStatus = {
    connection: "Bad",
    version: null,
    appName: null,
    qbFileName: null,
    errorMsg: response.error
  };
  store.dispatch(setStatus(response.data ?? badStatus));
}

export const abortQuery = async (queryId: string) => {
  const url = `${API_URL}abort?queryId=${queryId}`;
  abort(url);
};

export const abortAllQueries = async () => {
  const url = `${API_URL}abort/all`;
  abort(url);
};

const abort = (url: string) => {
  fetch(url, { method: "POST" })
  .then(response => {
    if (response.ok) {
      console.log("Queries aborted successfully.");
    }else{
      console.error("Abort request failed:", response.statusText);          
    }
  })
  .catch(error => console.error("Abort request error:", error));
}

const toCamelCase = (str: string) =>
  str.charAt(0).toLowerCase() + str.slice(1);

const transformKeysToCamelCase = <T extends Record<string, any>>(obj: T): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => transformKeysToCamelCase(item));
  }
  if (obj && typeof obj === "object") {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        toCamelCase(key),
        transformKeysToCamelCase(value),
      ])
    );
  }
  return obj;
};
