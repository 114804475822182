import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { supabase_products } from "../../../../database/supabaseClient";
import { TextField } from "../../../../components/TextField";
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import { QbRelData } from "../data";
import { PostgrestError } from "@supabase/supabase-js";
import { QbItemRelation } from "../types";
import QbItem from "./QbItem";
import { QbItemChannelAssemblyConflicts, QbItemChannelAssemblyDependencies } from "../../../../database/Products";

type QbItemsProps = {
  displayTitle?: boolean;
  lensOptions: {id: number, name: string}[];
  mountingOptions: {id: number, name: string}[];
  finishOptions: {id: number, name: string}[];
  getQbRelation: () => Promise<QbRelData>;
  createQbRelation: (listId: string) => Promise<PostgrestError | null>;
  saveQbRelation: (quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts, listId: string) => Promise<PostgrestError | null>;
  deleteQbRelation: (listId: string) => Promise<PostgrestError | null>;
  displayAlert: (message: string, severity: "success" | "error") => void;
  hideDependenciesAndConflicts?: boolean;
}

const QbItems = ({ getQbRelation, lensOptions, mountingOptions, finishOptions, createQbRelation, saveQbRelation, deleteQbRelation, displayAlert, hideDependenciesAndConflicts = false}: QbItemsProps) => {

  const [qbItems, setQbItems] = useState<QbItemRelation[]>([]);
  const [newItemNameInput, setNewItemNameInput] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const queryData = useCallback(async () => {
    const data = await getQbRelation();
    const transformedData = data.map(d => {
      return { 
        list_id: d.qbItem?.list_id ?? '', 
        full_name: d.qbItem?.full_name ?? '', 
        quantity: d.quantity,
        dependencies: d.dependencies,
        conflicts: d.conflicts,
      };
    });
    setQbItems(transformedData);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const queryQbItem = useCallback(async (fullName: string) => {
    const { data, error } = await supabase_products
    .from('qb_items').select('*').eq('full_name', fullName).single();
    if(error) console.error(error.message);
    if(!data){
      setErrorText(`Unable to find QuickBooks item ${fullName}`);
      return;
    }
    return data;
  }, []);
  
  const createRelation = useCallback(async (listId: string) => {
    const error = await createQbRelation(listId);
    if(error){ 
      displayAlert(`Error creating link to QB Item. Error: ${error.message}`, 'error');
      return false;
    }
    return true
  }, []);

  const handleAttemptLink = () => {
    if(!validateInput()) return;
    setLoading(true);
    queryQbItem(newItemNameInput).then(data => {
      if(!data) {
        setLoading(false);
        return;
      }
      createRelation(data.list_id).then((success) => {
        if(success){
          queryData().then(() => {
            setLoading(false);
            setNewItemNameInput('');
            displayAlert(`Successfully created link to QB Item ${newItemNameInput}`, 'success');
          });
        }else{
          setLoading(false)
        }
      });
    });
  }

  const validateInput = () => {
    setErrorText('');
    if(newItemNameInput === '') {
      setErrorText('Must enter a value.');
      return false;
    }
    if(qbItems.find(qbi => qbi.full_name === newItemNameInput)){
      setErrorText('That QuickBooks item is already linked.');
      return false;
    }
    return true;
  }

  return (
    <Box>
      {
        qbItems.length === 0 &&
        <Typography sx={{color: 'text.secondary'}}>No Linked Items</Typography>
      }
      {
        qbItems.length > 0 &&
        <Box display='flex' flexDirection='column' gap={1} mb={1}>
          {
            qbItems.map((qbItem, index) => (
              <React.Fragment key={qbItem.list_id}>
                <QbItem
                  qbItem={qbItem} 
                  lensOptions={lensOptions}
                  mountingOptions={mountingOptions}
                  finishOptions={finishOptions}
                  saveQbRelation={(quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts) => saveQbRelation(quantity, dependencies, conflicts, qbItem.list_id)}
                  deleteQbRelation={() => deleteQbRelation(qbItem.list_id)}
                  reloadData={queryData}
                  displayAlert={displayAlert} 
                  hideDependenciesAndConflicts={hideDependenciesAndConflicts}
                />
                {
                  index !== qbItems.length - 1 &&
                  <Divider flexItem />
                }
              </React.Fragment>
            ))
          }
        </Box>
      }
      <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end' >
        <TextField
          label='Item Name'
          value={newItemNameInput}
          onChange={(e) => setNewItemNameInput(e.target.value)}
        />
        <Button 
          startIcon={<AddLinkOutlinedIcon />} 
          onClick={() => handleAttemptLink()}
        >
          Link
        </Button>
        {
          loading &&
          <CircularProgress />
        }
      </Box>
      {
        errorText !== '' &&
        <Typography color='error' mt={1}>{errorText}</Typography>
      }
    </Box>
  );
};

export default QbItems;