import React from "react";
import {TableCell, Typography } from "@mui/material";

type CellProps = {
  sx?: object;
  children?: React.ReactNode;
}

const Cell = ({ sx = {}, children = "" }: CellProps) => {
  return (
    <TableCell sx={{ ...sx }}>
      <Typography>{children}</Typography>
    </TableCell>
  );
};

export default Cell;