import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { supabase } from "./database/supabaseClient";
import { Session } from "@supabase/supabase-js";
import { queryProfile } from "./database/Profile";
import { AppDispatch, RootState } from "./store/store";
import { setProfile } from "./store/reducers/profileSlice";
import { CssBaseline, createTheme,  ThemeProvider, Container } from "@mui/material";
import { ThemeMode, themeSettings } from "./theme";
import Auth from "./auth";
import Topbar from "./scenes/_global/Topbar";
import Sidebar from "./scenes/_global/Sidebar";
import Dashboard from "./scenes/dashboard";
import LeadTimes from "./scenes/lead-times/Index";
import Tapetool from "./scenes/tools/tapetool";
import CheckInventory from "./scenes/tools/check-inventory";
import CommissionChecker from "./scenes/tools/commission-checker";
import HLStearnsXmlTool from "./scenes/tools/hls-xml";
import ProgrammingDescPriceTool from "./scenes/tools/programming-desc-pricing";
import PermissionsList from "./scenes/admin/permissions/index";
import { hasPermission } from "./database/Permissions";
import EasySpecConfig from "./scenes/admin/easy-spec-config";
import EasySpec from "./scenes/tools/easy-spec";
import ProductConfig from "./scenes/data/product-config";
import QbDataQuery from "./scenes/tools/qb-data-query";

function App() {
  const [session, setSession] = useState<Session | null>(null);
  const { profile, theme: { darkMode } } = useSelector((state: RootState) => state);
  const dispatch = useDispatch<AppDispatch>();

  const themeMode: ThemeMode = darkMode ? "dark" : "light";
  const theme = useMemo(() => createTheme(themeSettings(themeMode)), [themeMode]);
  
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });    
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);
  
  const GetProfile = useCallback(async () => {
    if(!session) return;
    const profile = await queryProfile(session.user.id);
    if(profile) dispatch(setProfile(profile));
  }, [session, dispatch]);
  
  useEffect(() => {
    if (session) GetProfile();
  }, [session, GetProfile]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        {!session ? (
          <Auth />
        ) : (
          <>
            <Sidebar userEmail={session.user.email ?? ""} />
            <Container maxWidth='xl'>
              <Topbar />
              <main className="content">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route
                    path="/lead-times"
                    element={
                      hasPermission(profile, "LEAD_TIMES") &&
                      <LeadTimes />
                    }
                  />
                  <Route
                    path="/tools/tapetool"
                    element={
                      hasPermission(profile, "TOOL_TAPETOOL") &&
                      <Tapetool />
                    }
                  />
                  <Route
                    path="/tools/check-inventory"
                    element={
                      hasPermission(profile, "CHECK_INVENTORY") &&
                      <CheckInventory />
                    }
                  />
                  <Route
                    path="/tools/commission-checker"
                    element={
                      hasPermission(profile, "TOOL_COMMISSION_CHECKER") &&
                      <CommissionChecker />
                    }
                  />
                  <Route
                    path="/tools/hls-xml"
                    element={
                      hasPermission(profile, "TOOL_HLS_XML") &&
                      <HLStearnsXmlTool />
                    }
                  />
                  <Route
                    path="/tools/programming-desc-price"
                    element={
                      hasPermission(profile, "TOOL_PROG_PRICE_DESC") &&
                      <ProgrammingDescPriceTool />
                    }
                  />
                  <Route
                    path="/tools/easy-spec"
                    element={
                      hasPermission(profile, "EASY_SPEC_TOOL") &&
                      <EasySpec />
                    }
                  />               
                  <Route
                    path="/tools/qb-data-query"
                    element={
                      hasPermission(profile, "QB_DATA_QUERY") &&
                      <QbDataQuery />
                    }
                  />               
                  <Route
                    path="/data/products/*"
                    element={
                      hasPermission(profile, "PRODUCT_CONFIG") &&
                      <ProductConfig />
                    }
                  />
                  <Route
                    path="/admin/easy-spec-config/*"
                    element={
                      hasPermission(profile, "EASY_SPEC_CONFIG") &&
                      <EasySpecConfig />
                    }
                  />
                  <Route
                    path="/admin/permissions"
                    element={
                      hasPermission(profile, "MODIFY_PERMISSIONS") &&
                      <PermissionsList />
                    }
                  />
                </Routes>
              </main>
            </Container>
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
