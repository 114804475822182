import React from "react";
import {
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import { PermissionRecord, UserPermissionRecord } from "../../../../../database/PublicTypes";
import { Profile } from "../../../../../database/Profile";
import Cell from "./Cell";

type PermissionRowProps = {
  permission: PermissionRecord;
  userPermissions: UserPermissionRecord[];
  profiles: Profile[];
  OnViewProfiles: (profiles: Profile[], granted: boolean) => void;
}

const PermissionRow = ({ permission, userPermissions, profiles, OnViewProfiles }: PermissionRowProps) => {

  const profilesGranted = profiles.filter(
    (profile) => !!userPermissions.find(
      (userPermission) => userPermission.permission_name === permission.name && userPermission.user_id === profile.id
  ));

  const profilesDisallowed = profiles.filter(profile => !profilesGranted.find(profileGranted => profileGranted.id === profile.id));

  return (
    <TableRow hover >
      <Cell sx={{ verticalAlign: "top" }}>{permission.name}</Cell>
      <Cell sx={{ verticalAlign: "top" }}>{permission.description}</Cell>
      <TableCell sx={{ minWidth: "250px" }}>
        { profilesGranted.length }
        { profilesGranted.length > 0 && 
          <Button sx={{ marginLeft: 2}} onClick={() => OnViewProfiles(profilesGranted, true)}>View</Button>
        }
      </TableCell>
      <TableCell sx={{ minWidth: "250px" }}>
        { profilesDisallowed.length }
        { profilesDisallowed.length > 0 && 
          <Button sx={{ marginLeft: 2}} onClick={() => OnViewProfiles(profilesDisallowed, false)}>View</Button>
        }
      </TableCell>
    </TableRow>
  );
};

export default PermissionRow;