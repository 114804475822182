import React, { ChangeEvent, useState } from "react";
import { Box, TableCell, Typography, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import StyledNumberInput from "../../../../components/StyledNumberInput";
import ForceZeroOrGreater from "../../../../utils/ForceZeroOrGreater";
import { Run } from "../types";
import { Ft2In, Mm2In } from "../../../../global_functions/UomConversion";
import { LedTapeStrip } from "../../../../global_functions/RunCalculations";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';

type NumberInputProps = {
  label: string;
  value: number | string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onContextMenu?: (event: React.MouseEvent) => void;
  ml?: string;
  onFocused: (field: number) => void;
  onBlurred?: () => void;
  fieldIndex?: number;
  OnInputRef?: (ref: HTMLInputElement | null, field:number) => void;
  disabled?: boolean;
}

const NumberInput = ({ label, value, onChange, onContextMenu, ml, onFocused, onBlurred, fieldIndex, OnInputRef, disabled = false }: NumberInputProps) => {
  return (
    <StyledNumberInput
      type="number"
      label={label}
      value={value === 0 ? "" : value}
      onChange={onChange}
      onContextMenu={onContextMenu}
      onFocus={() => onFocused(fieldIndex ?? 0)}
      onBlur={onBlurred}
      autoComplete="off"
      inputRef={(ref) => (OnInputRef && OnInputRef(ref, fieldIndex ?? 0))}
      disabled={disabled}
      sx={{ 
        marginLeft: ml,
        maxWidth: "50px"
      }}
    />
  );
};

type RunProps = {
  run: Run;
  onQtyUpdate: (qty: number) => void;
  onFeetUpdate: (feet: number) => void;
  onInchesUpdate: (inches: number) => void;
  onFocused: (field: number) => void;
  onBlurred: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  OnInputRef: (ref: HTMLInputElement | null, field:number) => void;
  rowNum: number;
  isBulkReel: boolean;
}

const RunRow = ({ run, onQtyUpdate, onFeetUpdate, onInchesUpdate, onFocused, onBlurred, dragHandleProps, OnInputRef, rowNum, isBulkReel }: RunProps) => {
  const theme = useTheme();
  const colors = theme.colors;

  const [copiedDesc, setCopiedDesc] = useState<boolean>(false);

  const { buildDetails, channelReq, leadsReq, totalFootage, totalWatts, wattsPerLead, ledTapeRun } = run.ledTapeRunLine;

  const cutIntervalMm = ledTapeRun.specs.cut_interval_mm ?? 0;
  const maxIntervals = ledTapeRun.specs.max_cut_intervals ?? 0;
  let exceedsMaxRunLength = false;
  const strips: LedTapeStrip[] = ledTapeRun.strips;
  for (let i = 0; i < strips.length; i++) {
    if(strips[i].cutLengthMm > (cutIntervalMm * maxIntervals)){
      exceedsMaxRunLength = true;
      break;
    }
  }

  const isFirstInFmcRun = run.inFeed === 'lead' && !!run.fmc;
  const fmcRunExceedsMaxRunLength = run.totalFmcRunIntervals && (run.totalFmcRunIntervals > maxIntervals);

  let wattsPerLeadProcessed = 0;
  if(!run.totalFmcRunWatts && wattsPerLead && wattsPerLead.length === 1 && wattsPerLead[0].watts > 0){
    wattsPerLeadProcessed = Number(wattsPerLead[0].watts.toFixed(2));
  }else if(isFirstInFmcRun && !!run.totalFmcRunWatts){
    wattsPerLeadProcessed = Number((run.totalFmcRunWatts).toFixed(2));
  }

  const handleQtyChange = (newValue: number) => {
    newValue = ForceZeroOrGreater(newValue);
    onQtyUpdate(newValue);
  };

  const handleFeetChange = (newValue: number) => {
    newValue = ForceZeroOrGreater(newValue);
    onFeetUpdate(newValue);
  };

  const handleInchesChange = (newValue: number) => {
    newValue = ForceZeroOrGreater(newValue);
    onInchesUpdate(newValue);
  };

  const handleResetQty = (event: React.MouseEvent) => {
    event.preventDefault();
    onQtyUpdate(0);
  };

  const handleResetFeet = (event: React.MouseEvent) => {
    event.preventDefault();
    onFeetUpdate(0);
  };

  const handleResetInches = (event: React.MouseEvent) => {
    event.preventDefault();
    onInchesUpdate(0);
  };

  const handleOnClickDesc = () => {
    navigator.clipboard.writeText(run.ledTapeRunLine.buildDetails);
    setCopiedDesc(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setCopiedDesc(false);
  };

  const delta = Mm2In(run.ledTapeRunLine.ledTapeRun.totalLengthMm) - (Ft2In(run.feetInput) + run.inchesInput);

  return (
    <>
      <TableCell sx={{ position: 'relative' }}>
        {
          <Box 
            {...dragHandleProps} 
            sx={{ 
              display: run.qtyInput > 0 && (run.feetInput > 0 || run.inchesInput > 0) ? 'block' : 'none',
              position: 'absolute',
              top: '8px',
              left: '-28px'
            }}
            tabIndex={-1}
          >
            <Typography
              sx={{
                padding: '3px',
                borderRadius: '3px',
                height: '40px',
                alignContent: "center",
                color: theme.palette.mode === 'light' ? colors.grey[700] : colors.grey[500],
                backgroundColor: 'transparent',
                transition: 'border 0.3s ease-in-out', // Adds smooth transition effect
                "&:hover": {
                  border: "1px dashed " + colors.blueAccent[500]
                }
              }}
            >
              #{rowNum}
            </Typography>
          </Box>
        }
        {
          run.inFeed === 'lead' &&
          <NumberInput
            label={isBulkReel ? "Reels" : "Runs"}
            value={run.qtyInput > 0 ? run.qtyInput : ''}
            onChange={(e) => handleQtyChange(Number(e.target.value))}
            onContextMenu={handleResetQty}
            onFocused={onFocused}
            fieldIndex={0}
            OnInputRef={OnInputRef}
          />
        }
      </TableCell>
      <TableCell align="left" sx={{ position: 'relative' }}>
        <Box>
          <NumberInput
            label="Feet"
            value={run.feetInput > 0 ? run.feetInput : ''}
            onChange={(e) => handleFeetChange(Number(e.target.value))}
            onContextMenu={handleResetFeet}
            onFocused={onFocused}
            onBlurred={onBlurred}
            fieldIndex={1}
            OnInputRef={OnInputRef}
            disabled={isBulkReel}
          />
          <NumberInput
            label="Inches"
            value={run.inchesInput > 0 ? run.inchesInput : ''}
            ml="5px"
            onChange={(e) => handleInchesChange(Number(e.target.value))}
            onContextMenu={handleResetInches}
            onFocused={onFocused}
            onBlurred={onBlurred}
            fieldIndex={2}
            OnInputRef={OnInputRef}
            disabled={isBulkReel}
          />
        </Box>
        {
          run.fmc && 
          <Box position='absolute' right={-8} bottom={-16}>
            <RedoOutlinedIcon style={{ transform: 'rotate(90deg)', marginRight: '5px' }} />
          </Box>
        }
      </TableCell>
      <TableCell align="left">
        {
          totalFootage > 0 &&
          <Typography>{totalFootage.toFixed(2)} Ft</Typography>
        }
      </TableCell>
      <TableCell align="left">
        {
          totalFootage > 0 &&
          <Typography
            color={isBulkReel ? '' : delta > 0 ? colors.delta.positive : colors.delta.negative}
          >
            {
              isBulkReel ? '-' : `${delta > 0 ? '+' : ''}${delta.toFixed(2)}"`
            }
          </Typography>
        }
      </TableCell>
      <TableCell align="left">
        <Tooltip
          title={copiedDesc ? "Copied!" : "Click to copy"}
          TransitionComponent={Fade}
          TransitionProps={{
            timeout: {
              enter: 800,
              exit: 0,
            },
          }}
          onClose={handleOnCloseCopyTooltip}
        >
          <Typography
            onClick={handleOnClickDesc}
            whiteSpace={"pre-line"}
            sx={{
              "&:hover": {
                cursor: "pointer",
                color: colors.blueAccent[500],
              },
            }}
            color={exceedsMaxRunLength || fmcRunExceedsMaxRunLength ? 'red' : ''}
          >
            {buildDetails}
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell align="left">
        <Typography>
          {
            isBulkReel &&
            '-'
          }
          {
            leadsReq > 0 &&
            leadsReq
          }
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>
          {
            channelReq > 0 &&
            channelReq
          }
        </Typography>
      </TableCell>
      <TableCell align="left">
        {
          !run.totalFmcRunWatts && wattsPerLead && wattsPerLead.length > 1 && wattsPerLead.map(wpl => (
            <Typography key={wpl.tapeLengthFt}>{`${wpl.watts.toFixed(2)}w (${wpl.tapeLengthFt} FT)`}</Typography>
          ))
        }
        {
          wattsPerLeadProcessed > 0 && <Typography>{wattsPerLeadProcessed}w</Typography>
        }
      </TableCell>
      <TableCell align="left">
        <Typography>
          {
            !run.totalFmcRunWatts && totalWatts > 0 ?
            `${totalWatts.toFixed(2)}w`
            : ''
          }
          {
            isFirstInFmcRun && !!run.totalFmcRunWatts && `${(run.totalFmcRunWatts * run.qtyInput).toFixed(2)}w`
          }
        </Typography>
      </TableCell>
    </>
  );
};

export default RunRow;
