import React, { useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, Typography } from '@mui/material';
import { TextField } from '../../../../../../components/TextField';
import { ChannelRecord } from '../../../../../../database/Products';
import { supabase_products } from '../../../../../../database/supabaseClient';
import { useNavigate } from 'react-router-dom';
import StyledNumberInput from '../../../../../../components/StyledNumberInput';

type NewChannelProps = {
  onCancel: () => void;
  displayAlert: (message: string, severity: "success" | "error") => void;
}

const NewChannel = ({ onCancel, displayAlert }: NewChannelProps) => {
  const navigate = useNavigate();

  const [nameInput, setNameInput] = useState<string>('');
  const [assemblySkuCodeInput, setAssemblySkuCodeInput] = useState<string>('');
  const [lengthInput, setLengthInput] = useState<number>(0);
  const [widthExtInput, setWidthExtInput] = useState<number>(0);
  const [widthIntInput, setWidthIntInput] = useState<number>(0);
  const [heightExtInput, setHeightExtInput] = useState<number>(0);
  const [heightIntInput, setHeightIntInput] = useState<number>(0);

  const canAdd = (
    nameInput !== '' &&
    assemblySkuCodeInput !== '' &&
    lengthInput > 0 &&
    widthExtInput > 0 && 
    widthIntInput > 0 &&
    heightExtInput > 0 &&
    heightIntInput > 0
  );

  const handleAdd = async () => {
    if(!canAdd) return;
    const newRecord: Omit<ChannelRecord, 'id'> = {
      name: nameInput,
      assembly_sku_code: assemblySkuCodeInput,
      length_m: lengthInput,
      width_external_mm: widthExtInput,
      width_internal_mm: widthIntInput,
      height_external_mm: heightExtInput,
      height_internal_mm: heightIntInput
    }
    const { data, error } = await supabase_products.from('channel').insert(newRecord).select('id').single();
    if(error) {
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    if(!data) return;
    navigate(`channel/${data.id}`);
  }

  return (
    <>
    <DialogTitle id="import-dialog-title">
      Add a new Channel
    </DialogTitle>
    <DialogContent>
      <Box display='flex' flexDirection='column' gap={2}>
        <Box display='flex' flexDirection='row' gap={2}>
          <TextField 
            label='Name'
            value={nameInput} 
            onChange={(e) => setNameInput(e.target.value)}
            required
            helperText='i.e., "CH-502"'
          />
          <TextField 
            label='Assembly SKU Code'
            value={assemblySkuCodeInput} 
            onChange={(e) => setAssemblySkuCodeInput(e.target.value)}
            required
            helperText='i.e., "502"'
          />
        </Box>
        <StyledNumberInput 
          label='Length'
          type='number'
          maxWidth='100px'
          value={lengthInput > 0 ? lengthInput : ''}
          onChange={(e) => setLengthInput(Number(e.target.value))}
          InputProps={{ endAdornment: <InputAdornment position="end">Meters</InputAdornment> }}
          sx={{ marginBottom: '10px' }}
        />
        <Box>
          <Typography>External Dimensions</Typography>
          <Divider sx={{ marginBottom: '15px'}} />
          <Box display='flex' flexDirection='row' gap={2} px={2} mb={1}>
            <StyledNumberInput 
              label='Width'
              type='number'
              value={widthExtInput > 0 ? widthExtInput : ''}
              maxWidth='100px'
              onChange={(e) => setWidthExtInput(Number(e.target.value))}
              InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
            />
            <StyledNumberInput 
              label='Height'
              type='number'
              value={heightExtInput > 0 ? heightExtInput : ''}
              maxWidth='100px'
              onChange={(e) => setHeightExtInput(Number(e.target.value))}
              InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
            />
          </Box>
        </Box>
        <Box>
          <Typography>Internal Dimensions</Typography>
          <Divider sx={{ marginBottom: 'px'}} />
          <Box display='flex' flexDirection='row' gap={2} pl={2}>
            <StyledNumberInput 
              label='Width'
              type='number'
              value={widthIntInput > 0 ? widthIntInput : ''}
              maxWidth='100px'
              onChange={(e) => setWidthIntInput(Number(e.target.value))}
              InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
            />
            <StyledNumberInput 
              label='Height'
              type='number'
              value={heightIntInput > 0 ? heightIntInput : ''}
              maxWidth='100px'
              onChange={(e) => setHeightIntInput(Number(e.target.value))}
              InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
              fullWidth
            />
          </Box>
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={handleAdd} variant='contained' color='success' disabled={!canAdd}>
        Add
      </Button>
    </DialogActions>
    </>
  );
};

export default NewChannel;