import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent, 
  Switch,
  Typography,
} from "@mui/material";
import Header from "../../../components/Header";
import SoData from "./SoData";
import TextInput from "../../../components/TextInput";
import QBLocalLinkStatusBadge from "../../../qbll/QBLocalLinkStatusBadge";
import QbLocalLinkInstructions from "../../../qbll/QbLocalLinkInstructions";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { queryItemSiteInventory, queryQuote, querySalesOrder} from "../../../qbll/qbll";
import { useSelector } from "react-redux";
import { QBLLResponse, Quote, SalesOrder } from "../../../qbll/types";
import { RootState } from "../../../store/store";
import { Site } from "../../../qbll/types";

function updateItemSites(jsonObject: any, listId: string, newSites: Site[] | undefined) {
  // Check if jsonObject is actually an object or array
  if (typeof jsonObject !== "object" || jsonObject === null) return;
  // Iterate over keys if it's an object, or over elements if it's an array
  for (const key in jsonObject) {
    // Check if the current property/key is 'item'
    if (key === "item" && jsonObject[key].listId === listId) {
      // Update inventory.sites field to a new object
      jsonObject[key].inventory = jsonObject[key].inventory || {};
      jsonObject[key].inventory.sites = newSites;
    } else if (key === "inventory" && jsonObject["listId"] === listId) {
      // We're in the item of an assembly child that matches the target listId
      jsonObject[key].sites = newSites;
    }
    // Recursively call the function for nested objects or arrays
    updateItemSites(jsonObject[key], listId, newSites);
  }
  return jsonObject;
}

const CheckInventory = () => {
  const [queryObject, setQueryObject] = useState<"Sales Order" | "Quote">("Sales Order");
  const [refNumber, setRefNumber] = useState("");
  const [queryResponse, setQueryResponse] = useState<QBLLResponse<SalesOrder | Quote> | null>(null);
  const [queryingInventory, setQueryingInventory] = useState(false);
  const [includeSites, setIncludeSites] = useState(true);
  const { querying, status: qbllStatus } = useSelector((state: RootState) => state.qbll);

  const canQuery = !querying && qbllStatus !== null && qbllStatus.connection === "Good";

  const getTxnInv = useCallback(
    async (refNum: string, objType: "Sales Order" | "Quote", includeSites: boolean) => {
      if (!refNum || !objType) return;
      setQueryResponse(null);
      let response: QBLLResponse<SalesOrder | Quote> | null = null;
      if(objType === "Sales Order"){
        response = await querySalesOrder(refNum, true, true, includeSites);
      }else{
        response = await queryQuote(refNum, true, true, includeSites);
      }
      setQueryResponse(response);
    },
    [setQueryResponse]
  );

  const getItemSiteInv = useCallback(
    async (listId: string) => {
      if (!listId) return;
      const response = await queryItemSiteInventory(listId);
      if(response.data === null) return;
      const queriedSites = response.data[0].sites;
      const toBeUpdated = JSON.parse(JSON.stringify(queryResponse?.data)); // Deep copy
      const updatedData = updateItemSites(toBeUpdated, listId, queriedSites);
      setQueryResponse({ ...response, data: updatedData });
    },
    [queryResponse]
  );

  const handleChangeQueryType = (event: SelectChangeEvent ) => {
    setQueryObject(event.target.value as "Sales Order" | "Quote");
  };

  const handleClickSubmitQuery = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    await submitQuery();
  };

  const submitQuery = async () => {
    setQueryingInventory(true);
    await getTxnInv(refNumber, queryObject, includeSites);
    setQueryingInventory(false);
  }

  return (
    <Box m="20px">
      <Header
        title="CHECK INVENTORY"
        subtitle="Check Inventory of a given Sales Order."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end' mb='20px'>
            <FormControl
              sx={{ flexDirection: "row", marginRight: "5px" }}
              size="small"
            >
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                value={queryObject}
                label="Type"
                onChange={handleChangeQueryType}
                disabled={!canQuery}
                sx={{ minWidth: "120px" }}
              >
                <MenuItem value={"Sales Order"}>Sales Order</MenuItem>
                <MenuItem value={"Quote"}>Quote</MenuItem>
              </Select>
            </FormControl>
            <TextInput
              label={`${queryObject} #`}
              value={refNumber}
              updateValue={setRefNumber}
              disabled={!canQuery}
              onEnterPress={submitQuery}
            />
            <Button
              variant="contained"
              color="info"
              onClick={(e) => handleClickSubmitQuery(e)}
              disabled={!canQuery}
              sx={{ marginLeft: "10px" }}
            >
              Query
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                setRefNumber("");
                setQueryResponse(null);
              }}
              disabled={queryResponse?.data == null}
              sx={{ marginLeft: "5px" }}
            >
              Clear
            </Button>
          </Box>

          <QBLocalLinkStatusBadge position="right" />
        </Box>

        <FormControl sx={{ marginBottom: "10px" }}>
          <FormControlLabel
            control={
              <Switch
                title="Force Feet"
                checked={includeSites}
                onChange={(e) => setIncludeSites(e.target.checked)}
                color="secondary"
                size="small"
              />
            }
            label="Include Site Allocation"
          />
        </FormControl>

        {qbllStatus?.connection === "Bad" && qbllStatus?.errorMsg === "0x80040408" && (
          <Box display="flex">
            <ErrorOutlineOutlinedIcon />
            <Typography marginLeft="8px">Unable to connect to local QuickBook client.</Typography>
          </Box>
        )}
        
        {qbllStatus?.connection === "Bad" && qbllStatus?.errorMsg === "0x80040417" && (
          <Box display="flex">
            <ErrorOutlineOutlinedIcon />
            <Typography marginLeft="8px">The company file is not open in QuickBooks.</Typography>
          </Box>
        )}

        {qbllStatus?.connection === "Bad" &&
          qbllStatus?.errorMsg === "Failed to fetch" && <QbLocalLinkInstructions />}

        {!queryingInventory && queryResponse?.status === 404 && (
          <Typography>Sales Order Not Found...</Typography>
        )}

        {!queryingInventory ? (
          queryResponse?.data && (
            <SoData
              objType={queryObject}
              txnData={queryResponse.data}
              onQueryItemSiteInventory={getItemSiteInv}
            />
          )
        ) : (
          <Box>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default CheckInventory;
