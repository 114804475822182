import React, { useEffect, useRef } from "react";
import {
  Box,
  Chip,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { abortAllQueries, abortQuery, refreshStatus } from "./qbll";
import { useLocation } from "react-router-dom";
import { usePageNavigateWarning } from "../usePageNavigateWarning";

type StatusBadgeProps = {
  position: "right" | "left";
}

const QBLocalLinkStatusBadge = ({ position }: StatusBadgeProps) => {
  const { status: qbllStatus, querying, query, queryId } = useSelector((state: RootState) => state.qbll);

  const { connection, appName, version, qbFileName, errorMsg } = qbllStatus;

  const location = useLocation();

  // Create refs to track the latest values of isQuerying and queryId
  const isQueryingRef = useRef(querying);
  const queryIdRef = useRef(queryId);

  // Update refs whenever state changes
  useEffect(() => {
    isQueryingRef.current = querying;
  }, [querying]);
  
  useEffect(() => {
    queryIdRef.current = queryId;
  }, [queryId]);

  useEffect(() => {
    return () => {
      if(isQueryingRef.current){
        if(queryIdRef.current){
          abortQuery(queryIdRef.current);
        }else{
          abortAllQueries();
        }
      }
    };
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isQueryingRef.current) {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome to display the warning dialog
      }
    };
    const handleUnload = () => {
      if(isQueryingRef.current){
        if(queryIdRef.current){
          console.log('unloading1')
          abortQuery(queryIdRef.current);
        }else{
          console.log('unloading2')
          abortAllQueries();
        }
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, []);

  usePageNavigateWarning('A query is still in progress. Are you sure you want to leave?', querying);

  const tooltipTitle = () => {
    return (
      <>
        {!qbllStatus && (
          <Typography>
            Pending status response from QbLocalLink application...
          </Typography>
        )}
        <Typography>{appName}</Typography>
        <Typography>{version}</Typography>
        <Typography>
          Connection: {connection === "" ? "Fetching" : connection}
        </Typography>
        {
          querying &&
          <Typography>
            Query In Progress: {query}
          </Typography>
        }
        {
          queryId &&
          <Typography>
            Query ID: {queryId}
          </Typography>
        }
        <Typography>Opened File: {qbFileName}</Typography>
        {errorMsg === "0x80040408" && 
          <Typography>Unable to connect to QuickBooks.</Typography>
        }
        {errorMsg === "0x80040438" && 
          <Typography>Multiple QuickBooks applications are open.</Typography>
        }
        {errorMsg === "0x80040417" && errorMsg && 
          <Typography>The company file is not open in QuickBooks.</Typography>
        }
        {errorMsg !== "0x80040408" && errorMsg !== "0x80040438" && errorMsg !== "0x80040417" && errorMsg && 
          <Typography>Error: {errorMsg}</Typography>
        }
        <hr />
        <Typography>
          <a
            href="https://storage.googleapis.com/qblocallink/QBLocalLink_Setup.exe"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#86c9b9" }}
          >
            Download
          </a>
        </Typography>
      </>
    );
  };

  const tooltipStatusIcon = () => {
    return qbllStatus != null ? (
      qbllStatus.connection === "Bad" ? (
        <WarningOutlinedIcon color="error" />
      ) : (
        <CheckOutlinedIcon color="success" />
      )
    ) : (
      <QuestionMarkOutlinedIcon />
    );
  };

  useEffect(() => {
    refreshStatus();
  }, []);

  return (
    <Tooltip title={tooltipTitle()}>
      <Box
        sx={{
          marginLeft: "5px",
          float: position,
        }}
      >
        <Chip
          icon={tooltipStatusIcon()}
          label={`QBLocalLink`}
          variant="outlined"
        />
        <IconButton
          size="small"
          disabled={querying}
          onClick={() => refreshStatus()}
        >
          {querying && query.includes("api/status") ? (
            <CircularProgress
              color="info"
              size={20}
            />
          ) : (
            <CachedOutlinedIcon />
          )}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default QBLocalLinkStatusBadge;
