import React, { useCallback, useEffect, useState } from "react";
import { LedTapeOptionValueRecord, LedTapeRecord } from "../../../../../database/Products";
import { Box, Button, Card, CardContent, CircularProgress } from "@mui/material";
import AttributesList, { Content } from "../../../../../components/AttributesList";
import { TextField } from "../../../../../components/TextField";
import { supabase_products } from "../../../../../database/supabaseClient";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { DisplayAlert } from "../../types";

type SkuFormulaProps = {
  record: LedTapeRecord;
  displayAlert: DisplayAlert;
  reloadData: () => Promise<void>;
}

const SkuFormulaConfig = ({ record, displayAlert, reloadData }: SkuFormulaProps) => {
  
  const [optionsData, setOptionsData] = useState<(Partial<LedTapeOptionValueRecord>)[] | null>(null);
  const [input, setInput] = useState<string>(record.sku_formula);

  const queryData = useCallback(async () => {
    const { data, error } = await supabase_products
    .from('led_tape_option_values')
    .select('type, display_name, sku_code')
    .eq('led_tape_slug', record.slug)
    .order('display_name');
    if(error) console.error(error);
    setOptionsData(data ?? []);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const optionsList: Content = {
    headers: {
      attributeLabel: 'Option Slug',
      valuesLabel: 'Values (Label / SKU Code)',
      align: 'left'
    },
    attributes: [
      {
        name: 'Color',
        label: '{Color}',
        values: 
          optionsData?.filter(opt => opt.type === 'Color')?.map(c => ({ 
            name: c.display_name ?? '',
            code: c.sku_code ?? ''
          })) ?? []        
      },
      {
        name: 'SDCM',
        label: '{SDCM}',
        values: 
          optionsData?.filter(opt => opt.type === 'SDCM')?.map(sdcm => ({ 
            name: sdcm.display_name ?? '',
            code: sdcm.sku_code ?? ''
          })) ?? []        
      },
      {
        name: 'Lumens',
        label: '{Lumens}',
        values: 
          optionsData?.filter(opt => opt.type === 'Lumens')?.map(lumens => ({ 
            name: lumens.display_name ?? '',
            code: lumens.sku_code ?? ''
          })) ?? []        
      },
      {
        name: 'Kelvin Temp',
        label: '{Kelvin Temp}',
        values: 
          optionsData?.filter(opt => opt.type === 'Kelvin Temp')?.map(temp => ({ 
            name: temp.display_name ?? '',
            code: temp.sku_code ?? ''
          })) ?? []        
      },
    ]
  }

  optionsList.attributes = optionsList.attributes.filter(attr => attr.values.length > 0);

  const canSave = record.sku_formula !== input;

  const handleInputChange = (newValue: string) => {
    setInput(newValue);
  }
  
  const handleSave = async () => {
    if(!canSave) return;
    const { error } = await supabase_products
    .from('led_tape')
    .update({ sku_formula: input })
    .eq('id', record.id);
    if(error){
      console.error(error);
      displayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    reloadData().then(() => {
      displayAlert('Saved successfully!', 'success');
    });
  }

  return (
    <Card>
      <CardContent>
        {
          !optionsData &&
          <CircularProgress />
        }
        {
          optionsData &&
          <>
          <Box display='flex' flexDirection='row' gap={2} mb={2} alignItems='flex-end'>
            <TextField
              label="SKU Formula"
              value={input}
              updated={canSave}
              onChange={(e) => handleInputChange(e.target.value)}
              minWidth="400px"
            />
            <Button 
              color="secondary" 
              variant="contained" 
              disabled={!canSave}
              startIcon={<SaveOutlinedIcon />}
              onClick={() => handleSave()}
            >
              Save
            </Button>
          </Box>
          <Box maxWidth='650px'>
            <AttributesList content={optionsList} />
          </Box>
          </>
        }
      </CardContent>
    </Card>
  );
};

export default SkuFormulaConfig;