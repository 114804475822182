import React, { useEffect, useState } from "react";
import { Box, Divider, MenuItem, Switch, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { SelectField } from "../../../../components/TextField";
import ChipList from "./ChipList";
import { LineItemType, QueryParams } from "../types";

type SalesOrderQueryFiltersProps = {
  isQuerying: boolean;
  OnChangeQueryParams: (newQueryParams: QueryParams) => void;
}

const SalesOrderQueryFilters = ({ isQuerying, OnChangeQueryParams }: SalesOrderQueryFiltersProps) => {
  const [createdFrom, setCreatedFrom] = useState<Dayjs | null>(null);
  const [createdTo, setCreatedTo] = useState<Dayjs | null>(dayjs());
  //const [esdFrom, setEsdFrom] = useState<Dayjs | null>(null);
  //const [esdTo, setEsdTo] = useState<Dayjs | null>(null);
  const [includeLineItems, setIncludeLineItems] = useState<boolean>(false);
  const [compileLineItems, setCompileLineItems] = useState<boolean>(false);
  const [excludeZeroQtyLines, setExcludeZeroQtyLines] = useState<boolean>(false);
  const [excludeBlankLines, setExcludeBlankLines] = useState<boolean>(false);
  //const [pullInventoryData, setPullInventoryData] = useState<boolean>(false);
  //const [pullPoData, setPullPoData] = useState<boolean>(false);
  const [lineItemType, setLineItemItem] = useState<LineItemType>('BOTH');
  const [inclusiveItemCodes, setInclusiveItemCodes] = useState<boolean>(false);
  const [itemCodes, setItemCodes] = useState<string[]>([]);
  //const [inclusiveStatuses, setInclusiveStatuses] = useState<boolean>(false);
  //const [statuses, setStatuses] = useState<string[]>([]);

  useEffect(() => {
    if(includeLineItems) return;
    setCompileLineItems(false);
    setExcludeZeroQtyLines(false);
    //setPullInventoryData(false);
    //setPullPoData(false);
  }, [includeLineItems]);

  useEffect(() => {
    OnChangeQueryParams({ 
      createdDateFrom: createdFrom, 
      createdDateTo: createdTo,
      includeLineItems: includeLineItems,
      compileLineItems: compileLineItems,
      excludeBlankLines: excludeBlankLines,
      excludeZeroQtyLines: excludeZeroQtyLines,
      lineItemType: lineItemType,
      inclusiveItemCodes: inclusiveItemCodes,
      itemCodes: itemCodes,
      //inclusiveStatuses: inclusiveStatuses,
      //statuses: statuses
    });
  }, [
    createdFrom, 
    createdTo, 
    includeLineItems, 
    compileLineItems, 
    excludeBlankLines, 
    excludeZeroQtyLines, 
    lineItemType, 
    includeLineItems, 
    itemCodes, 
    //inclusiveStatuses, 
    //statuses
  ]);

  return (
    <Box display='flex' flexDirection='column' gap={2} my={2}>
      <Box display='flex' flexDirection='row' gap={2} alignItems='baseline'>
        <Typography>Created:</Typography>
        <Box display='flex' flexDirection='row' gap={2}>
          <DatePicker 
            label='From' 
            value={createdFrom}
            onChange={(value) => setCreatedFrom(value)}
            maxDate={createdTo ?? undefined}
            disabled={isQuerying}
          />
          <DatePicker 
            label='To' 
            value={createdTo}
            onChange={(value) => setCreatedTo(value)}
            minDate={createdFrom ?? undefined}
            disabled={isQuerying}
          />
        </Box>
        {
          /*
        <Divider orientation="vertical" flexItem />
        <Typography>ESD:</Typography>
        <Box display='flex' flexDirection='row' gap={2}>
          <DatePicker 
            label='From' 
            value={esdFrom}
            onChange={(value) => setEsdFrom(value)}
            maxDate={esdTo ?? undefined}
            disabled={isQuerying}
          />
          <DatePicker 
            label='To' 
            value={esdTo}
            onChange={(value) => setEsdTo(value)}
            minDate={esdFrom ?? undefined}
            disabled={isQuerying}
          />
        </Box>
          */
        }
      </Box>
      <Box display='flex' flexDirection='row' gap={2}>
        <Box>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={includeLineItems} 
              onChange={(e) => setIncludeLineItems(e.target.checked)}
              disabled={isQuerying}
            />
            <Typography>Include Items</Typography>
          </Box>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={compileLineItems} 
              onChange={(e) => setCompileLineItems(e.target.checked)}
              disabled={!includeLineItems || isQuerying}
            />
            <Typography>Compile Line Items</Typography>
          </Box>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={excludeBlankLines} 
              onChange={(e) => setExcludeBlankLines(e.target.checked)}
              disabled={!includeLineItems || isQuerying}
            />
            <Typography>Exclude Blank Lines</Typography>
          </Box>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={excludeZeroQtyLines} 
              onChange={(e) => setExcludeZeroQtyLines(e.target.checked)}
              disabled={!includeLineItems || isQuerying}
            />
            <Typography>Exclude 0 Qty Lines</Typography>
          </Box>
          {
          /*
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={pullInventoryData} 
              onChange={(e) => setPullInventoryData(e.target.checked)}
              disabled={!includeLineItems || isQuerying}
            />
            <Typography>Pull Inventory Data</Typography>
          </Box>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={pullPoData} 
              onChange={(e) => setPullPoData(e.target.checked)}
              disabled={!includeLineItems || isQuerying}
            />
            <Typography>Pull Purchase Order Data</Typography>
          </Box>
          */
          }
          <Box my={1}>
            <SelectField
              label='Line Item Type'
              value={lineItemType}
              onChange={(e) => setLineItemItem(e.target.value as LineItemType)}
              minWidth='100px'
              disabled={!includeLineItems || isQuerying}
            >
              <MenuItem value={'BOTH'}>Both</MenuItem>
              <MenuItem value={'NORMAL'}>Normal</MenuItem>
              <MenuItem value={'GROUP'}>Group</MenuItem>
            </SelectField>
          </Box>
        </Box>
        {
          includeLineItems &&
          <Box>
            <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
              <Switch
                checked={inclusiveItemCodes} 
                onChange={(e) => setInclusiveItemCodes(e.target.checked)}
                disabled={isQuerying}
              />
              <Typography>{inclusiveItemCodes ? 'Inclusive' : 'Exclusive'}</Typography>
            </Box>
            <ChipList 
              label={`${inclusiveItemCodes ? 'Include' : 'Exclude'} Item Codes`}
              OnListChanged={(itemCodes) => setItemCodes(itemCodes)}
              disabled={isQuerying}
            />
          </Box>
        }
        {
          /*
        <Divider orientation="vertical" flexItem />
        <Box>
          <Box display='flex' flexDirection='row' ml={-1} alignItems='flex-end'>
            <Switch
              checked={inclusiveStatuses} 
              onChange={(e) => setInclusiveStatuses(e.target.checked)}
              disabled={isQuerying}
            />
            <Typography>{inclusiveStatuses ? 'Inclusive' : 'Exclusive'}</Typography>
          </Box>
          <ChipList 
            label={`${inclusiveStatuses ? 'Include' : 'Exclude'} Statuses`}
            OnListChanged={(statuses) => setStatuses(statuses)}
            disabled={isQuerying}
          />
        </Box>
          */
        }
      </Box>
      <Divider />
    </Box>
  );
};

export default SalesOrderQueryFilters;