import { LedTape } from "../../../database/Products";
import { BuildLedTapeBulkReelLine, BuildLedTapeRunLine, CalculateRuns, CutDistribution, ForceRounding, InFeed, LedTapeRunLine } from "../../../global_functions/RunCalculations";
import { Ft2Mm, In2Mm } from "../../../global_functions/UomConversion";
import { Run } from "./types";

export const IsRunBlank = (run: Run) => {
  if(run.qtyInput === 0 && run.feetInput === 0 && run.inchesInput === 0) return true;
  return false;
}

export const CreateNewEmptyRun = (
  ledTape: LedTape,
  cutDistribution: CutDistribution, 
  rounding: ForceRounding | 'Auto',
  isBulkReel: boolean,
  wattsInDetails: boolean
): Run => ({
  runId: Math.random() * 100,
  qtyInput: 0,
  feetInput: 0,
  inchesInput: 0,
  ledTapeRunLine: {...CalculateRunLine(ledTape, 0, 0, 0, 'lead', cutDistribution, rounding, isBulkReel, wattsInDetails)},
  inFeed: 'lead'
});

export const CreateNewRun = (
  ledTape: LedTape,
  quantity: number,
  feet: number,
  inches: number,
  cutDistribution: CutDistribution, 
  rounding: ForceRounding | 'Auto',
  isBulkReel: boolean,
  wattsInDetails: boolean
): Run => ({
  runId: Math.random() * 100,
  qtyInput: quantity,
  feetInput: feet,
  inchesInput: inches,
  ledTapeRunLine: {...CalculateRunLine(ledTape, quantity, feet, inches, 'lead', cutDistribution, rounding, isBulkReel, wattsInDetails)},
  inFeed: 'lead'
});

export const CalculateRunLine = (
  ledTape: LedTape, 
  qty: number,
  feet: number,
  inches: number,
  inFeed: InFeed,
  cutDistribution: CutDistribution, 
  rounding: ForceRounding | 'Auto',
  isBulkReel: boolean,
  wattsInDetails: boolean,
  ignoreMaxRunLength = false
): LedTapeRunLine => {
  const reqLengthMm = Ft2Mm(feet) + In2Mm(inches);
  if(isBulkReel) return BuildLedTapeBulkReelLine(ledTape, qty);
  const ledTapeRun = CalculateRuns(ledTape, (qty > 0) ? reqLengthMm : 0, cutDistribution, rounding === 'Auto' ? undefined : rounding, ignoreMaxRunLength);
  const ledTapeRunLine = BuildLedTapeRunLine(ledTapeRun, qty, wattsInDetails, inFeed);
  return ledTapeRunLine;
};

export const FilterRuns = (
  runs: Run[], 
  ledTape: LedTape, 
  cutDistribution: CutDistribution, 
  rounding: ForceRounding | 'Auto', 
  isBulkReel: boolean,
  wattsInDetails: boolean
): Run[] => {
  if(runs[runs.length - 1].ledTapeRunLine.totalFootage > 0){
    runs.push(CreateNewEmptyRun(ledTape, cutDistribution, rounding, isBulkReel, wattsInDetails));
  }
  const filteredRuns = runs.filter((run, i) => {
    if(i === runs.length-1) return true;
    if(isBulkReel && run.qtyInput === 0) return false;
    const noInputs = run.qtyInput === 0 && run.feetInput === 0 && run.inchesInput === 0;
    return !noInputs;
  });
  if(filteredRuns.length === 0) return runs;
  return(filteredRuns);
};

export const GetCompiledBuildDetails = (runs: Run[], conductors: number) => {
  return runs.reduce((prev, curr, index) => {
    const runLine = curr.ledTapeRunLine;
    if (runLine.totalFootage <= 0) return prev;
    let details = '';
    const didPrevHaveMultiLengths = index > 0 ? runs[index - 1]?.ledTapeRunLine.buildDetails.includes('\n') : false;
    const hasMultiLengths = runLine.buildDetails.includes('\n');
    if(hasMultiLengths && !didPrevHaveMultiLengths && index !== 0) details += '\n';
    if(hasMultiLengths) details += `-- ${runLine.totalFootage.toFixed(2)} FT --\n`;
    const hasFmc = !!curr.fmc;
    const prevHadFmc = !!runs[index - 1]?.fmc;
    const secondPrevHadFmc = !!runs[index - 2]?.fmc;
    if(hasFmc && !prevHadFmc && !secondPrevHadFmc && (index > 0)) details += '\n';
    if(hasFmc && !prevHadFmc) details += 'FML-' + conductors + 'C-6\n';
    details += runLine.buildDetails;
    if(curr.fmc) details += '\n' + 'FMC-' + conductors + 'C';
    if(!hasFmc && prevHadFmc) details += '\n';
    if(hasMultiLengths) details += `\n`;
    return prev === "" ? details : prev + "\n" + details;
  }, "");
}