import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { LedTapeSpecRecord } from "../../../../../../../database/Products";
import { supabase_products } from "../../../../../../../database/supabaseClient";

type BatchColumnUpdateDialogProps = {
  open: boolean,
  targetField: keyof LedTapeSpecRecord;
  newValue: string;
  recordIds: number[];
  OnClose: () => void;
  OnUpdated: () => Promise<void>;
}

const BatchColumnUpdateDialog = ({ open, targetField, newValue, recordIds, OnClose, OnUpdated }: BatchColumnUpdateDialogProps) => {
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [erroredRecords, setErroredRecords] = useState<({index: number, id: number, [key: string]: string | number, error: string})[]>([]);

  const handleConfirmClick = async () => {
    if(recordIds.length <= 0) return;
    setHasStarted(true);
    const records: (Pick<LedTapeSpecRecord, 'id'> & Partial<LedTapeSpecRecord>)[] = recordIds.map(id => ({ id: id}));
    const newRecords = records.map(record => ({ id: record.id, [targetField as (keyof LedTapeSpecRecord)]: newValue }));
    for(let i = 0; i < newRecords.length; i++){
      const specRecord = newRecords[i];
      const { data, error } = await supabase_products
      .from('led_tape_specs')
      .update(specRecord)
      .eq('id', specRecord.id);
      if(error){
        console.error(error);
        setErroredRecords(prev => [...prev, {...specRecord, index: i, error: error.message}]);
        setProgress(((i + 1) / newRecords.length) * 100);
        return;
      }
      console.log('Record with id', specRecord.id, 'updated successfully:', !!data);
      setProgress(((i + 1) / newRecords.length) * 100);
    }
  }

  const handleOnCloseClick = () => {
    OnClose();
    setErroredRecords([]);
    setProgress(0);
    setHasStarted(false);
  }
  
  const handleOnFinishClick = () => {
    handleOnCloseClick();
    OnUpdated();
  }

  const totalRecords = recordIds.length;

  return (
    <Dialog
      open={open}
      onClose={() => { return; }}
    >
      {
        !hasStarted ?
        <DialogTitle>Are you sure?</DialogTitle>
        :
        <DialogTitle>Updating</DialogTitle>        
      }
      <DialogContent>
        {
          !hasStarted ?
          <DialogContentText>{`You are about to update `}<b>all {totalRecords} spec rows</b>{` with the value of "`}<b>{newValue}</b>{`" for field "`}<b>{targetField}</b>{`". Are you sure you want to continue?`}</DialogContentText>
          :
          <DialogContentText>{`Updating `}<b>all {totalRecords} spec rows</b>{` with the value of "`}<b>{newValue}</b>{`" for field "`}<b>{targetField}</b>{`"...`}</DialogContentText>
        }
        {
          hasStarted &&
          <Box my={2}>
            <Typography>{Math.round(progress)}% ({Math.round(totalRecords * (progress/100))}/{totalRecords})</Typography>
            <LinearProgress variant="determinate" color="secondary" value={progress} />
            {
              erroredRecords.length > 0 &&
              <Box my={2}>
                <Typography color='error'><b>Failed while updating record. Aborting!</b></Typography>
                <Box>
                  {
                    erroredRecords.map(errRec => (
                      <Typography color='error'>Index: {errRec.index} - ID: {errRec.id} - Reason: {errRec.error}</Typography>
                    ))
                  }
                </Box>
              </Box>
            }
          </Box>
        }
        <DialogActions>
          {
            hasStarted && Math.round(progress) >= 100 &&
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOnFinishClick}
            >
              Finish
            </Button>
          }
          {
            hasStarted && erroredRecords.length > 0 &&
            <Button
              color="secondary"
              variant="contained"
              onClick={handleOnCloseClick}
            >
              Close
            </Button>
          }
          {
            !hasStarted &&
            <>
              <Button
                onClick={handleOnCloseClick}
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmClick} 
                color="secondary"
                variant="contained"
              >
                Yes, update all rows
              </Button>
            </>
          }
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default BatchColumnUpdateDialog;