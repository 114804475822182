import { Box, Button, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SelectField, TextField } from "../../../../../../../components/TextField";
import { LedTapeSpecRecord } from "../../../../../../../database/Products";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import BatchColumnUpdateDialog from "./BatchColumnUpdateDialog";
import { GridColDef } from "@mui/x-data-grid";

type BatchColumnUpdateProps = {
  columns: GridColDef<LedTapeSpecRecord>[];
  recordIds: number[];
  OnUpdated: () => Promise<void>;
}

const BatchColumnUpdate = ({ columns, recordIds, OnUpdated }: BatchColumnUpdateProps) => {
  const [batchColumnInput, setBatchColumnInput] = useState<(keyof LedTapeSpecRecord) | null>();
  const [batchColumnValueInput, setBatchColumnValueInput] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if(!batchColumnInput) return;
    setBatchColumnValueInput('');
  }, [batchColumnInput]);

  const handleUpdateClick = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const filteredColumns = columns.filter(column => (
    column.field !== 'id' &&
    column.field !== 'option_color' &&
    column.field !== 'option_kelvin_temp' &&
    column.field !== 'option_lumens' &&
    column.field !== 'option_sdcm'
  ));

  return (
    <Box display='flex' flexDirection='row' gap={1} alignContent='center'>
      <SelectField
        label='Field'
        value={batchColumnInput}
        onChange={(e) => setBatchColumnInput(e.target.value as (keyof LedTapeSpecRecord))}
        minWidth='100px'
      >
        {
          filteredColumns.map(column => (
            <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
          ))
        }
      </SelectField>
      <TextField
        label='New Value'
        value={batchColumnValueInput}
        onChange={(e) => setBatchColumnValueInput(e.target.value)}
      />
      <Box display='flex' flexDirection='row' alignItems='center'>
        <Button 
          startIcon={<FileUploadOutlinedIcon />}
          onClick={handleUpdateClick} 
          disabled={batchColumnInput === null || batchColumnValueInput === ''}
        >
          Update All Rows
        </Button>
      </Box>
      {
        batchColumnInput &&
        <BatchColumnUpdateDialog
          open={openDialog}
          targetField={batchColumnInput}
          newValue={batchColumnValueInput}
          recordIds={recordIds}
          OnClose={handleCloseDialog}
          OnUpdated={OnUpdated}
        />
      }
    </Box>
  );
};

export default BatchColumnUpdate;