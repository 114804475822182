import { GetAddressString } from "../../../utils/Address";
import { QuoteRow, SalesOrderRow } from "./types";
import { ToDollar } from "../../../utils/Currency";

export const SalesOrderColumns = [
  { field: 'id', headerName: 'Id', editable: false },
  { field: 'txnId', headerName: 'Txn ID', editable: false },
  { field: 'txnDate', headerName: 'Txn Date', editable: false },
  { field: 'timeCreated', headerName: 'Time Created', editable: false },
  { field: 'timeModified', headerName: 'Time Last Modified', editable: false },
  { field: 'refNumber', headerName: 'Ref #', editable: false },
  { field: 'poNumber', headerName: 'PO #', editable: false },
  { field: 'customer', headerName: 'Customer', editable: false, valueGetter: (value: never, row: SalesOrderRow) => row.customer.fullName },
  { field: 'billAddress', headerName: 'Billing Address', editable: false, valueGetter: (value: never, row: SalesOrderRow) => GetAddressString(row.billAddress) },
  { field: 'shipAddress', headerName: 'Shipping Address', editable: false, valueGetter: (value: never, row: SalesOrderRow) => GetAddressString(row.shipAddress) },
  { field: 'salesRep', headerName: 'Sales Rep', editable: false },
  { field: 'commissionRate', headerName: 'Commission Rate', editable: false, valueGetter: (value: never, row: SalesOrderRow) => `${row.commissionRate}%` },
  { field: 'totalAmount', headerName: 'Total Amount', editable: false, valueGetter: (value: never, row: SalesOrderRow) =>  ToDollar(row.totalAmount ?? 0) },
  { field: 'projectName', headerName: 'Project Name', editable: false },
  { field: 'csr', headerName: 'CSR', editable: false },
  { field: 'quote', headerName: 'Quote #', editable: false },
  { field: 'status', headerName: 'Status', editable: false },
  { field: 'terms', headerName: 'Terms', editable: false },
  { field: 'dueDate', headerName: 'Due Date', editable: false },
  { field: 'template', headerName: 'Template', editable: false },
  { field: 'isFullyInvoiced', headerName: 'Is Fully Invoiced', editable: false },
  { field: 'shipDate', headerName: 'ESD', editable: false },
  { field: 'lineItem_txnLineId', headerName: 'Txn Line ID', editable: false, lineItem: true },
  { field: 'lineItem_listId', headerName: 'List ID', editable: false, lineItem: true },
  { field: 'lineItem_type', headerName: 'Type', editable: false, lineItem: true },
  { field: 'lineItem_fullName', headerName: 'Name', editable: false, lineItem: true },
  { field: 'lineItem_description', headerName: 'Description', editable: false, lineItem: true },
  { field: 'lineItem_quantity', headerName: 'Quantity', editable: false, lineItem: true },
  { field: 'lineItem_priceRate', headerName: 'Price Rate', editable: false, valueGetter: (value: never, row: SalesOrderRow) =>  ToDollar(row.lineItem_priceRate ?? 0), lineItem: true },
  { field: 'lineItem_priceTotal', headerName: 'Price Total', editable: false, valueGetter: (value: never, row: SalesOrderRow) =>  ToDollar(row.lineItem_priceTotal ?? 0), lineItem: true },
  { field: 'lineItem_uomOverride', headerName: 'UoM', editable: false, lineItem: true },
  { field: 'lineItem_invoiced', headerName: 'Invoiced', editable: false, lineItem: true },
  { field: 'lineItem_backOrdered', headerName: 'Back Ordered', editable: false, lineItem: true },
  { field: 'lineItem_openBalance', headerName: 'Open Balance', editable: false, lineItem: true },
];

export const QuoteColumns = [
  { field: 'id', headerName: 'Id', editable: false },
  { field: 'txnId', headerName: 'Txn ID', editable: false },
  { field: 'txnDate', headerName: 'Txn Date', editable: false },
  { field: 'timeCreated', headerName: 'Time Created', editable: false },
  { field: 'timeModified', headerName: 'Time Last Modified', editable: false },
  { field: 'refNumber', headerName: 'Ref #', editable: false },
  { field: 'customer', headerName: 'Customer', editable: false, valueGetter: (value: never, row: QuoteRow) => row.customer.fullName },
  { field: 'billAddress', headerName: 'Billing Address', editable: false, valueGetter: (value: never, row: QuoteRow) => GetAddressString(row.billAddress) },
  { field: 'shipAddress', headerName: 'Shipping Address', editable: false, valueGetter: (value: never, row: QuoteRow) => GetAddressString(row.shipAddress) },
  { field: 'salesRep', headerName: 'Sales Rep', editable: false },
  { field: 'commissionRate', headerName: 'Commission Rate', editable: false, valueGetter: (value: never, row: QuoteRow) => `${row.commissionRate}%` },
  { field: 'totalAmount', headerName: 'Total Amount', editable: false, valueGetter: (value: never, row: QuoteRow) =>  ToDollar(row.totalAmount ?? 0) },
  { field: 'projectName', headerName: 'Project Name', editable: false },
  { field: 'csr', headerName: 'CSR', editable: false },
  { field: 'template', headerName: 'Template', editable: false },
  { field: 'lineItem_txnLineId', headerName: 'Txn Line ID', editable: false, lineItem: true },
  { field: 'lineItem_listId', headerName: 'List ID', editable: false, lineItem: true },
  { field: 'lineItem_type', headerName: 'Type', editable: false, lineItem: true },
  { field: 'lineItem_fullName', headerName: 'Name', editable: false, lineItem: true },
  { field: 'lineItem_description', headerName: 'Description', editable: false, lineItem: true },
  { field: 'lineItem_quantity', headerName: 'Quantity', editable: false, lineItem: true },
  { field: 'lineItem_priceRate', headerName: 'Price Rate', editable: false, valueGetter: (value: never, row: QuoteRow) =>  ToDollar(row.lineItem_priceRate ?? 0), lineItem: true },
  { field: 'lineItem_priceTotal', headerName: 'Price Total', editable: false, valueGetter: (value: never, row: QuoteRow) =>  ToDollar(row.lineItem_priceTotal ?? 0), lineItem: true },
  { field: 'lineItem_uomOverride', headerName: 'UoM', editable: false, lineItem: true },
];