import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Button, Card, CardContent, CircularProgress, Divider, InputAdornment, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { ChannelAssemblyRecord, ChannelRecord, QbItemChannelAssemblyConflicts, QbItemChannelAssemblyDependencies } from '../../../../../database/Products';
import { supabase_products } from '../../../../../database/supabaseClient';
import { TextField } from '../../../../../components/TextField';
import StyledNumberInput from '../../../../../components/StyledNumberInput';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { ClickableTextCell } from '../../components/table';
import QbItems from '../../components/QbItems';
import { CreateQbRelChannel, DeleteQbRelChannel, GetQbRelChannel, SaveQbRelChannel } from '../../data';
import { AlertUi } from '../../types';

const ChannelConfig = () => {
  const colors = useTheme().colors;
  const navigate = useNavigate();
  const { id: idParam } = useParams<{ id: string }>();

  const [channel, setChannel] = useState<ChannelRecord | null>(null);
  const [channelAssemblies, setChannelAssemblies] = useState<Pick<ChannelAssemblyRecord, 'id' | 'name'>[]>([]);
  
  const [nameInput, setNameInput] = useState<string>('');
  const [assemblySkuCodeInput, setAssemblySkuCodeInput] = useState<string>('');
  const [lengthInput, setLengthInput] = useState<number>(0);
  const [widthExtInput, setWidthExtInput] = useState<number>(0);
  const [widthIntInput, setWidthIntInput] = useState<number>(0);
  const [heightExtInput, setHeightExtInput] = useState<number>(0);
  const [heightIntInput, setHeightIntInput] = useState<number>(0);

  const [queryError, setQueryError] = useState<string>('');
  const [displayAlert, setDisplayAlert] = useState<AlertUi>({display: false, message: '', severity: 'success'});

  const queryData = useCallback(async () => {
    const { data, error } = await supabase_products
    .from('channel').select('*').eq('id', idParam).single();
    if(error) console.error(error);
    if(!data){
      setQueryError('Unable to query channel data.');
      return;
    }
    setChannel(data);
    setNameInput(data.name);
    setAssemblySkuCodeInput(data.assembly_sku_code);
    setLengthInput(data.length_m);
    setWidthExtInput(data.width_external_mm);
    setWidthIntInput(data.width_internal_mm);
    setHeightExtInput(data.height_external_mm);
    setHeightIntInput(data.height_internal_mm);
  }, []);

  const queryAssemblies = useCallback(async (channelId: number) => {
    const { data, error } = await supabase_products
    .from('channel_assemblies').select('id, name').eq('channel_id', channelId);
    if(error) console.error(error);
    if(!data){
      setQueryError('Unable to query channel assemblies data.');
      return;
    }
    setChannelAssemblies(data);
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  useEffect(() => {
    if(!channel) return;
    queryAssemblies(channel.id);
  }, [channel]);

  const updatedRecord: Omit<ChannelRecord, 'id'> = {
    name: nameInput,
    assembly_sku_code: assemblySkuCodeInput,
    length_m: lengthInput,    
    width_internal_mm: widthIntInput,
    width_external_mm: widthExtInput,
    height_internal_mm: heightIntInput,
    height_external_mm: heightExtInput,
  };

  const updatedKeys: (keyof Omit<ChannelRecord, 'id'>)[] = [];
  let updated = false;

  if(channel){
    for (const key in channel) {
      if (Object.prototype.hasOwnProperty.call(channel, key)) {
        // Type assertion to access the property values
        if(key === 'id') continue;
        const value1 = channel[key as keyof Omit<ChannelRecord, 'id'>];
        const value2 = updatedRecord[key as keyof Omit<ChannelRecord, 'id'>];
        if (value1 !== value2) {
          updatedKeys.push(key as keyof Omit<ChannelRecord, 'id'>);
          updated = true;
        }
      }
    }
  }

  const handleCloseAlert = () => {
    setDisplayAlert({display: false, message: '', severity: 'success'});
  };

  const handleDisplayAlert = (message: string, severity: "success" | "error") => {
    setDisplayAlert({display: true, message: message, severity: severity});
  };

  const handleSave = async () => {
    if(!channel || !channel.id) return;
    const { error } = await supabase_products
    .from('channel').update({...updatedRecord}).eq('id', channel.id);
    if(error) {
      console.error(error);
      handleDisplayAlert(`Failed to save. Error Message: "${error.message}"`, 'error');
      return;
    }
    queryData().then(() => {
      handleDisplayAlert('Saved successfully!', 'success');
    });
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap="15px" mb={2}>
        <Button 
          startIcon={<ArrowBackIosNewOutlinedIcon color="inherit" />}
          onClick={() => navigate(`../?type=channel`)}
        >
          Channels List
        </Button>
        {channel && <Typography variant="h3">{channel.name}</Typography>}
      </Box>
      { 
        !channel && queryError === '' &&
        <CircularProgress />
      }
      {
        queryError !== '' &&
        <Typography color='red'>{queryError}</Typography>
      }
      <Box display='flex' flexDirection='column' gap={2} maxWidth={'800px'}>
        <Box display='flex' flexDirection='row' gap={2}>
        {
          channel &&
          <Card sx={{ width: '100%'}}>
            <CardContent>
              <Box display='flex' gap={2} mb={2}>
                <TextField 
                  label='Name'
                  value={nameInput}
                  onChange={(e) => setNameInput(e.target.value)}
                  updated={updatedKeys.includes('name')}
                  />
                <TextField 
                  label='Assembly Code'
                  value={assemblySkuCodeInput}
                  onChange={(e) => setAssemblySkuCodeInput(e.target.value)}
                  updated={updatedKeys.includes('assembly_sku_code')}
                />
              </Box>
              <Box display='flex' gap={2} mb={2}>
                <StyledNumberInput 
                  label='Length'
                  type='number'
                  value={lengthInput}
                  onChange={(e) => setLengthInput(Number(e.target.value))}
                  InputProps={{ endAdornment: <InputAdornment position="end">Meters</InputAdornment> }}
                  sx={{ 
                    maxWidth: '125px',
                    '& .MuiInputBase-input': {
                      backgroundColor: updatedKeys.includes('length_m') ? colors.updatedValue.yellow : ''
                    }
                  }}
                />
              </Box>
              <Box mb={2}>
                <Typography>External Dimensions</Typography>
                <Divider sx={{ marginBottom: 2}}/>
                <Box display='flex' gap={2} pl={2}>
                  <StyledNumberInput 
                    label='Width'
                    type='number'
                    value={widthExtInput}
                    onChange={(e) => setWidthExtInput(Number(e.target.value))}
                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                    sx={{ 
                      maxWidth: '125px',
                      '& .MuiInputBase-input': {
                        backgroundColor: updatedKeys.includes('width_external_mm') ? colors.updatedValue.yellow : ''
                      }
                    }}
                  />
                  <StyledNumberInput 
                    label='Height'
                    type='number'
                    value={heightExtInput}
                    onChange={(e) => setHeightExtInput(Number(e.target.value))}
                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                    sx={{ 
                      maxWidth: '125px',
                      '& .MuiInputBase-input': {
                        backgroundColor: updatedKeys.includes('height_external_mm') ? colors.updatedValue.yellow : ''
                      }
                    }}
                  />
                </Box>
              </Box>
              <Box mb={2}>
                <Typography>Internal Dimensions</Typography>
                <Divider sx={{ marginBottom: 2}}/>
                <Box display='flex' gap={2} pl={2}>
                  <StyledNumberInput 
                    label='Width'
                    type='number'
                    value={widthIntInput}
                    onChange={(e) => setWidthIntInput(Number(e.target.value))}
                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                    sx={{ 
                      maxWidth: '125px',
                      '& .MuiInputBase-input': {
                        backgroundColor: updatedKeys.includes('width_internal_mm') ? colors.updatedValue.yellow : ''
                      }
                    }}
                  />
                  <StyledNumberInput 
                    label='Height'
                    type='number'
                    value={heightIntInput}
                    onChange={(e) => setHeightIntInput(Number(e.target.value))}
                    InputProps={{ endAdornment: <InputAdornment position="end">mm</InputAdornment> }}
                    sx={{ 
                      maxWidth: '125px',
                      '& .MuiInputBase-input': {
                        backgroundColor: updatedKeys.includes('height_internal_mm') ? colors.updatedValue.yellow : ''
                      }
                    }}
                  />
                </Box>
              </Box>
              <Divider sx={{ marginY: '15px' }}/>
              <Button 
                color='secondary' 
                variant='contained' 
                startIcon={<SaveOutlinedIcon />}
                disabled={!updated || !channel}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </CardContent>
          </Card>
        }      
        {
          channel &&
          <Card sx={{ width: '100%'}}>
            <CardContent >
              <Box>
                <Typography mb={2}>Channel Assemblies using this Channel</Typography>
                <Table size='small'>
                  <TableHead sx={{ background: colors.primary[400]}}>
                    <TableRow>
                      <TableCell><Typography><b>Channel Assmebly</b></Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      channelAssemblies.map(assembly => (
                        <TableRow key={assembly.id}>
                          <ClickableTextCell clickTo={`../channel-assembly/${assembly.id}`}>{assembly.name}</ClickableTextCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </Box>
            </CardContent>
          </Card>
        }
        </Box>
        {
          channel &&
          <Card sx={{ width: '100%'}}>
            <CardContent>
              <Typography variant="h5">QuickBooks Items</Typography>
              <Divider sx={{ marginBottom: '15px' }} />
              <Box px={2}>
                <QbItems
                  displayTitle={true}
                  lensOptions={[]}
                  mountingOptions={[]}
                  finishOptions={[]}
                  getQbRelation={() => GetQbRelChannel(channel.id)}
                  createQbRelation={(listId: string) => CreateQbRelChannel(listId, channel.id)}
                  saveQbRelation={(quantity: number, dependencies: QbItemChannelAssemblyDependencies, conflicts: QbItemChannelAssemblyConflicts, listId: string) => SaveQbRelChannel(quantity, listId, channel.id)}
                  deleteQbRelation={(listId: string) => DeleteQbRelChannel(listId, channel.id)}
                  displayAlert={handleDisplayAlert}
                  hideDependenciesAndConflicts={true}
                />
              </Box>
            </CardContent>
          </Card>
        }
      </Box>
      <Snackbar
        open={displayAlert.display}
        autoHideDuration={3500}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={displayAlert.severity}
          sx={{ width: "100%", fontSize: "20px" }}
        >
          {displayAlert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChannelConfig;