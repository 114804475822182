import { Box } from "@mui/system";
import React, { useState } from "react";
import { TextField } from "../../../../components/TextField";
import { Chip } from "@mui/material";

type ChipListProps = {
  label: string;
  disabled?: boolean;
  OnListChanged: (items: string[]) => void;
}

const ChipList = ({ label, disabled = false, OnListChanged }: ChipListProps) => {
  const [input, setInput] = useState<string>('');
  const [items, setItems] = useState<string[]>([]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if(event.key === 'Enter'){
      event.preventDefault();
      const trimmedInput = input.trim();
      if(!trimmedInput) return;
      if(!items.includes(trimmedInput)){
        const newItems = [...items, trimmedInput];
        setItems(newItems);
        OnListChanged(newItems);
      }
      setInput('');
    }
  };
  
  const handleDelete = (itemToDelete: string) => {
    setItems((prev) => prev.filter((status) => status !== itemToDelete));
    OnListChanged(items);
  };

  return (
    <Box>
      <TextField
        label={label}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Press Enter to add"
        disabled={disabled}
      />
      <Box display='flex' gap={1} flexWrap='wrap' mt={2} width={150}>
        {
          items.map((item, index) => (
            <Chip
              key={index}
              label={item}
              onDelete={() => handleDelete(item)}
            />
          ))
        }
      </Box>
    </Box>
  );
};

export default ChipList;