import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Cell from "./Cell";
import { toLocale } from "./SoData";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ItemForTable } from "./types";

type ItemRowProps = {
  index: number;
  itemData: ItemForTable;
  checked: boolean;
  onChangedChecked: (selectIndex: number, isSelected: boolean) => void;
  queryItemSiteInventory: (listId: string) => Promise<void>;
}

const ItemRow = ({ index, itemData, checked, onChangedChecked, queryItemSiteInventory }: ItemRowProps) => {
  const colors = useTheme().colors;

  const [displaySites, setDisplaySites] = useState<boolean>(false);
  const [isQueryingSites, setIsQueryingSites] = useState<boolean>(false);

  const handleSubmitSiteQuery = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsQueryingSites(true);
    await queryItemSiteInventory(itemData.listId);
    setIsQueryingSites(false);
    setDisplaySites(true);
  };

  // Alphabetically sort sites by name
  const sites = itemData.sites;
  sites?.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const lakeviewSite = sites?.find((site) => site.name === "Lakeview");
  const lakeviewHasEnough = Number(lakeviewSite?.quantityOnHand) >= Number(itemData.quantityNeeded);

  const firloopSite = sites?.find((site) => site.name === "Fir Loop");
  const virtualFgSite = sites?.find((site) => site.name === "Virtual FG");

  if (sites && !lakeviewSite) sites.push({ listId: itemData.listId, name: "Lakeview", quantityOnHand: 0, uom: itemData.uom });
  if (sites && !firloopSite) sites.push({ listId: itemData.listId, name: "Fir Loop", quantityOnHand: 0, uom: itemData.uom });
  if (sites && !virtualFgSite) sites.push({ listId: itemData.listId, name: "Virtual FG", quantityOnHand: 0, uom: itemData.uom });

  const virtualFgOnHand = virtualFgSite?.quantityOnHand ?? 0;
  const itemQtyOnHand = toLocale(
    virtualFgOnHand > 0 ?
    itemData.onHand - virtualFgOnHand :
    itemData.onHand
  );

  return (
    <>
      <TableRow
        sx={{
          background: itemData.haveEnough ? "" : colors.tableRowHighlight["bad"],
        }}
      >
        <Cell>
          <Checkbox
            checked={checked}
            onChange={(e) => onChangedChecked(index, e.target.checked)}
            color="secondary"
            size="small"
          />
        </Cell>
        <Cell>{itemData.fullName}</Cell>
        <Cell>{toLocale(itemData.quantityNeeded)}</Cell>
        <Cell>{itemData.uom}</Cell>
        <Cell>{itemQtyOnHand}</Cell>
        <TableCell>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography>
              {toLocale(itemData.onSalesOrder ?? 0)}
            </Typography>
            {
              itemData.isAssemblyPart &&
              <Tooltip color="info" title={
                <Grid container>
                  <Grid item xs={9}>
                    <Typography>{itemData.parentPartNumber}:</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{itemData.onSalesOrderParent}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography>{itemData.fullName}:</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{itemData.onSalesOrderIndiv}</Typography>
                  </Grid>
                </Grid>
              }>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            }
          </Box>
        </TableCell>
        <Cell>{toLocale(itemData.onOrder ?? 0)}</Cell>
        <TableCell>
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent='right'>
            {!sites ? (
              !isQueryingSites &&
              <Button
                variant="outlined"
                color="info"
                onClick={(e) => handleSubmitSiteQuery(e)}
              >
                Query
              </Button>
            ) : (
              <>
                {!lakeviewHasEnough && <ErrorOutlinedIcon sx={{ color: "red" }} />}
                <IconButton
                  color="info"
                  onClick={() => setDisplaySites(!displaySites)}
                >
                  {displaySites ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </>
            )}
            {
              isQueryingSites &&
              <Box>
                <CircularProgress />
              </Box>
            }
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
          colSpan={7}
        />
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: "none" }}
          colSpan={1}
        >
          <Collapse
            in={displaySites}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <Table
                size="small"
                aria-label="purchases"
                sx={{ float: "right", border: "none" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Site</strong>
                    </TableCell>
                    <TableCell>
                      <strong>On Hand</strong>
                    </TableCell>
                    <TableCell>
                      <strong>UoM</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: "none" }}>
                  {sites?.map(
                    (site, index) =>
                      site.name !== "" && (
                        <TableRow
                          key={index}
                          sx={{
                            border: "none",
                            background:
                              site.name === "Lakeview" && !lakeviewHasEnough
                                ? colors.tableRowHighlight["bad"]
                                : "",
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                          >
                            {site.name}
                          </TableCell>
                          <TableCell>{toLocale(site.quantityOnHand)}</TableCell>
                          <TableCell>{itemData.uom}</TableCell>
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ItemRow;
