import { QbObjectType } from "../../../qbll/qbll";
import { LineItem, Quote, SalesOrder, SalesOrderLineItem } from "../../../qbll/types";
import { LineItemFields, QueryParams, QuoteRow, SalesOrderLineItemFields, SalesOrderRow } from "./types";

export const formatBatchToRows = (startingId: number, objType: QbObjectType, batch: (SalesOrder | Quote)[], queryParams: QueryParams): (SalesOrderRow | QuoteRow)[] => {
  if(objType === 'Sales Order'){
    return formatSalesOrderBatchToRow(startingId, batch as SalesOrder[], queryParams);
  }
  return formatQuoteBatchToRow(startingId, batch as Quote[], queryParams);
}

const formatSalesOrderBatchToRow = (startingId: number, batch: SalesOrder[], queryParams: QueryParams): SalesOrderRow[] => {
  const rows: SalesOrderRow[] = [];
  if(queryParams?.includeLineItems){
    batch.forEach(record => {
      if(!record.lineItems || record.lineItems.length === 0){
        rows.push({...record, id: startingId + rows.length});
      }else{
        const { lineItems } = record;
        lineItems.forEach(lineItem => {
          if(queryParams.lineItemType === 'GROUP' && lineItem.type !== 'Group') return;
          if(queryParams.lineItemType === 'NORMAL' && lineItem.type !== 'Normal') return;
          if(queryParams.excludeBlankLines && lineItem.listId === '' && lineItem.fullName === '' && lineItem.quantity === 0) return;
          if(queryParams.excludeZeroQtyLines && lineItem.type === 'Normal' && lineItem.quantity === 0) return;
          if(queryParams.itemCodes.length > 0){
            const matchesFound = queryParams.itemCodes.some(itemCode => lineItem.fullName.toLowerCase().includes(itemCode.toLowerCase()));
            if((queryParams.inclusiveItemCodes && !matchesFound) || (!queryParams.inclusiveItemCodes && matchesFound)) return;
          }
          rows.push({
            id: startingId + rows.length,
            ...record,
            ...getSalesOrderLineItemValues(lineItem)
          });
        })
      }
    });
  }else{
    rows.push(...batch.map((record, index) => ({...record, id: startingId + index})));
  }
  return rows;
}

const formatQuoteBatchToRow = (startingId: number, batch: Quote[], queryParams: QueryParams): QuoteRow[] => {
  const rows: QuoteRow[] = [];
  if(queryParams?.includeLineItems){
    batch.forEach(record => {
      if(!record.lineItems || record.lineItems.length === 0){
        rows.push({...record, id: startingId + rows.length});
      }else{
        const { lineItems } = record;
        lineItems.forEach(lineItem => {
          if(queryParams.lineItemType === 'GROUP' && lineItem.type !== 'Group') return;
          if(queryParams.lineItemType === 'NORMAL' && lineItem.type !== 'Normal') return;
          if(queryParams.excludeBlankLines && lineItem.listId === '' && lineItem.fullName === '' && lineItem.quantity === 0) return;
          if(queryParams.excludeZeroQtyLines && lineItem.type === 'Normal' && lineItem.quantity === 0) return;
          if(queryParams.itemCodes.length > 0){
            const matchesFound = queryParams.itemCodes.some(itemCode => lineItem.fullName.toLowerCase().includes(itemCode.toLowerCase()));
            if((queryParams.inclusiveItemCodes && !matchesFound) || (!queryParams.inclusiveItemCodes && matchesFound)) return;
          }
          rows.push({
            id: startingId + rows.length,
            ...record,
            ...getquoteLineItemValues(lineItem)
          });
        })
      }
    });
  }else{
    rows.push(...batch.map((record, index) => ({...record, id: startingId + index})));
  }
  return rows;
}

const getSalesOrderLineItemValues = (lineItem: SalesOrderLineItem): SalesOrderLineItemFields => {
  return {
    lineItem_txnLineId: lineItem.txnLineId,
    lineItem_listId: lineItem.listId,
    lineItem_type: lineItem.type,
    lineItem_fullName: lineItem.fullName,
    lineItem_description: lineItem.description,
    lineItem_quantity: lineItem.quantity,
    lineItem_priceRate: lineItem.priceRate,
    lineItem_priceTotal: lineItem.priceTotal,
    lineItem_uom: lineItem.unitOfMeasure,
    lineItem_uomOverride: lineItem.unitOfMeasureOverride,
    lineItem_invoiced: lineItem.invoiced,
    lineItem_backOrdered: lineItem.backOrdered,
    lineItem_openBalance: lineItem.openBalance
  }
}

const getquoteLineItemValues = (lineItem: LineItem): LineItemFields => {
  return {
    lineItem_txnLineId: lineItem.txnLineId,
    lineItem_listId: lineItem.listId,
    lineItem_type: lineItem.type,
    lineItem_fullName: lineItem.fullName,
    lineItem_description: lineItem.description,
    lineItem_quantity: lineItem.quantity,
    lineItem_priceRate: lineItem.priceRate,
    lineItem_priceTotal: lineItem.priceTotal,
    lineItem_uom: lineItem.unitOfMeasure,
    lineItem_uomOverride: lineItem.unitOfMeasureOverride
  }
}

export const compileLineItems = (objType: QbObjectType, existing: SalesOrderRow[] | QuoteRow[], toCompile: (SalesOrderRow | QuoteRow)[]): SalesOrderRow[] | QuoteRow[] => {
  const compiledRows = [...existing];
  toCompile.forEach(newRow => {
    const matchedRowIndex = compiledRows.findIndex(row => (
      (row.lineItem_listId === newRow.lineItem_listId) && (row.lineItem_uomOverride === newRow.lineItem_uomOverride)
    ));
    if(matchedRowIndex >= 0){
      const matchedRow = compiledRows[matchedRowIndex];
      compiledRows[matchedRowIndex] = (objType === 'Sales Order') ? 
        compileSalesOrderLine(matchedRow as SalesOrderRow, newRow as SalesOrderRow) : 
        compileQuoteLine(matchedRow as QuoteRow, newRow as QuoteRow);
    }else{
      compiledRows.push(newRow);
    }
  });
  return compiledRows.map((row, index) => ({ ...row, id: index }));
}

const compileSalesOrderLine = (existingRow: SalesOrderRow, newRow: SalesOrderRow ): SalesOrderRow => {
  const { lineItem_quantity, lineItem_priceTotal, lineItem_invoiced, lineItem_backOrdered, lineItem_openBalance } = existingRow;
  return {
    ...existingRow,
    lineItem_priceTotal: lineItem_priceTotal ? lineItem_priceTotal + (newRow.lineItem_priceTotal ?? 0) : newRow.lineItem_priceTotal,
    lineItem_quantity: lineItem_quantity ? lineItem_quantity + (newRow.lineItem_quantity ?? 0) : newRow.lineItem_quantity,
    lineItem_invoiced: lineItem_invoiced ? lineItem_invoiced + (newRow.lineItem_invoiced ?? 0) : newRow.lineItem_invoiced,
    lineItem_backOrdered: lineItem_backOrdered ? lineItem_backOrdered + (newRow.lineItem_backOrdered ?? 0) : newRow.lineItem_backOrdered,
    lineItem_openBalance: lineItem_openBalance ? lineItem_openBalance + (newRow.lineItem_openBalance ?? 0) : newRow.lineItem_openBalance
  }
}

const compileQuoteLine = (existingRow: QuoteRow, newRow: QuoteRow ): QuoteRow => {
  const { lineItem_quantity, lineItem_priceTotal } = existingRow;
  return {
    ...existingRow,
    lineItem_priceTotal: lineItem_priceTotal ? lineItem_priceTotal + (newRow.lineItem_priceTotal ?? 0) : newRow.lineItem_priceTotal,
    lineItem_quantity: lineItem_quantity ? lineItem_quantity + (newRow.lineItem_quantity ?? 0) : newRow.lineItem_quantity,
  }
}